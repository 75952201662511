@import '../utils/mq';
@import '../../styles/abstracts/variables';

.tooltip .react-tooltip-lite {
  border-radius: 4px !important;
  background: var(--color-white);
  color: var(--color-dark);
  font-size: 1.2rem !important;
  border: 1px solid var(--color-black-20);
  padding: 0.4rem 0.8rem !important;

  @include mq($from: m) {
    max-width: 40rem !important;
  }
}

.tooltip .react-tooltip-lite-down-arrow svg {
  transform: translateY(6px);
}

.tooltip .react-tooltip-lite-right-arrow svg {
  transform: rotate(270deg) translateY(4px) translateX(-6px);
}
.tooltip .react-tooltip-lite-up-arrow svg {
  transform: rotate(180deg) translateY(3px);
}
.tooltip .react-tooltip-lite-left-arrow svg {
  transform: rotate(90deg) translateY(3px) translateX(6px);
}

.tooltip__wrap {
  display: inline-block;
}
