@import '../../styles/utils/mq';

.howto {
  background-color: rgba(var(--color-secondary-rgb), 0.2);
  padding: 7.2rem 0;
}

.howto__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;

  @include mq($from: m) {
    height: auto;
  }
}

.howto__text {
  color: var(--color-dark-50);
  margin: 2.2rem 0;
  max-width: 40rem;
  text-align: center;

  @include mq($from: m) {
    margin: 5.6rem 0 3.2rem;
    max-width: 50rem;
  }
}

.howto__maintext {
  font-size: 1.6rem;
  letter-spacing: 0.15em;
  display: block;

  @include mq($from: m) {
    font-size: 2rem;
    letter-spacing: 0.15em;
  }
}

.howto__subtext {
  display: block;
  margin-top: 1.2rem;
}

.howto__image {
  display: flex;
  justify-content: center;

  img {
    width: 80%;
    max-width: 30rem;

    @include mq($from: m) {
      width: 100%;
    }
  }
}

.howto__button {
  min-height: 3.5rem;
}

@media (max-height: 660px) {
  .howto__inner {
    justify-content: flex-end;
    position: relative;
  }

  .howto__button,
  .howto__text {
    position: relative;
    z-index: 2;
  }

  .howto__text {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .howto__image {
    position: absolute;
    top: 0;
    opacity: 0.5;
    z-index: 1;

    img {
      height: 100%;
      width: 85%;
      max-width: 100%;
    }
  }
}
