@import '../../styles/utils/mq';

.inline-edit__wrap {
  max-height: 5.2rem;
  transition-property: max-height;
  transition-duration: 0.6s;
}

.inline-edit__wrap--edit {
  max-height: 9rem;
}

.inline-edit__controls {
  animation: fadeSlideInFromRight 0.3s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  opacity: 0;
  min-width: 8rem;
}
