@import '../../styles/fonts/font-mixins';
@import '../../styles/utils/mq';

.input {
  position: relative;
}

.input__tag {
  background-color: var(--color-white);
  border: 1px solid var(--color-dark-20);
  border-radius: var(--border-radius-default);
  height: 3.5rem;
  line-height: 3.5rem;
  padding: 0 0.8rem;
  font-size: 1.2rem;
  display: block;
  width: 100%;

  .input--dark & {
    background-color: var(--color-dark);
    color: var(--color-white);
    border-color: hsl(0, 0%, 70%);
  }

  &:focus {
    border-color: var(--color-primary);
  }

  &::placeholder {
    color: var(--color-dark-20);
  }

  .input--dark &:hover {
    border-color: var(--color-white);
  }

  .input--dark &:focus {
    box-shadow: 0 0 0 1px var(--color-primary);
    border-color: var(--color-primary);
  }

  .input--success & {
    border-color: var(--color-confirm);
  }

  .input--loading & {
    padding-right: 3.8rem;
  }

  @include mq($from: s) {
    padding: 0 1.6rem;
  }
}

.input__tag--textarea {
  line-height: 1.5;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.input__tag--big {
  @include font-headline(1);
  border-radius: 0;
  background-color: transparent;
  border-color: transparent transparent var(--color-black-20) transparent;
  padding: 0 0 0.8rem;

  &.input__tag[aria-invalid] {
    border-color: transparent transparent var(--color-warning) transparent;
  }

  .input--success &.input__tag {
    border-color: transparent transparent var(--color-confirm) transparent;
  }

  &:focus {
    border-color: transparent transparent var(--color-primary) transparent;
  }
}

// Invalid state
.input__tag[aria-invalid],
.input--error .input__tag {
  border-color: var(--color-warning);
}

// Disabled state
.input__tag[disabled] {
  color: var(--color-dark-20);
  cursor: not-allowed;
}

.input__label-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input__max-length {
  @include font-caption(var(--color-dark-50));

  &--max {
    color: var(--color-warning);
  }
}

.input__label {
  @include font-caption(var(--color-dark-50));

  display: block;

  .input--disabled & {
    color: var(--color-dark-20);
  }

  .input--dark & {
    color: var(--color-white);
  }
}

.input__error {
  background-color: var(--color-warning-50);
  font-size: 1.2rem;
  padding: 0 0.4rem;
  border-radius: 4px;
  position: absolute;
  bottom: -0.4rem;
  transform: translateY(100%);
  z-index: 1;

  .input--msg-top & {
    top: -0.8rem;
    transform: translateY(0);
    bottom: auto;
    right: 0;
  }
}

.input__success {
  background-color: var(--color-confirm-50);
}

.input__loader {
  position: absolute;
  right: 0.8rem;
  bottom: 1rem;
}

.input__clear {
  position: absolute;
  right: 0.8rem;
  bottom: 0.8rem;
  width: 2rem;
  height: 2rem;
  font-size: 1.2rem;
  background-color: var(--color-primary);
  color: var(--color-white);
  border-radius: 50%;
}
