@import '../../styles/abstracts/variables';
@import '../../styles/utils/mq';

$chipStatusColors: (
  'live' var(--color-secondary-25) var(--color-secondary-50),
  'draft' var(--color-warning-25) var(--color-warning-50),
  'idle' var(--color-dark-10) var(--color-dark-20)
);

.chip__wrap {
  display: inline-block;
}

.chip {
  appearance: none;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  border-radius: 0.4rem;
  padding: 0.2rem 0.4rem;
  border: 1px solid transparent;
  cursor: default;
  transition-property: border-color;
  vertical-align: top;
  line-height: 1.2em;

  &:is(button),
  &:is(a) {
    width: 100%;
  }

  &:is(button, a):not([disabled]) {
    cursor: pointer;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  .status-icon {
    margin-right: 0.4rem;
  }

  @include mq($from: m) {
    font-size: 1.2rem;
  }
}

.chip__icon {
  display: inline-flex;
  font-size: 0.7em;
  margin-right: 0.4rem;

  .chip--big & {
    font-size: 1em;
  }
}

.chip--hint,
.chip--big {
  display: flex;
}

.chip__wrap--hint,
.chip__wrap--big {
  display: block;

  .chip--hint,
  .chip--big {
    width: 100%;
  }
}

.chip--big {
  line-height: 3.5rem;
  font-size: 1.2rem;
  border-radius: var(--border-radius-default);
  padding: 0 0.8rem;

  &.chip--interactive {
    padding-right: 0;
  }
}

.chip--interaction {
  border-color: var(--color-primary);
}

.chip--private {
  background-color: var(--color-dark-10);
  font-size: 1.4rem;
}

.chip--image {
  padding-left: 0.2rem;
}

.chip__inner {
  .chip__wrap--hint &,
  .chip__wrap--big & {
    width: 100%;
    display: flex;
  }
}

.chip__text {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  user-select: none;

  .chip--hint:not(.chip--private) & {
    text-transform: uppercase;
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.07em;
  }
}

.chip__hint {
  color: var(--color-dark-50);
  font-size: 1rem;
  letter-spacing: 0.07em;
  margin-left: auto;
  padding-left: 1.2rem;
  white-space: nowrap;
}

.chip__interaction {
  color: var(--color-dark);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  line-height: 1;
  transition-property: padding;
  flex-shrink: 0;
  padding: 0 0.4rem 0 0.8rem;
  margin: -0.2rem -0.4rem -0.2rem auto;
  min-height: 1.8rem;

  .chip--interaction:not(.chip--big) & {
    padding-left: 1rem;
  }

  .chip__hint + & {
    margin-left: 0;
  }

  .chip--big & {
    height: 3.5rem;
    width: 3.5rem;
    padding-right: 0.8rem;
  }

  @include mq($from: s) {
    .chip__hint + & {
      margin-left: 1.2rem;
    }
  }
}

.chip__image {
  overflow: hidden;
  width: 3rem;
  height: 3rem;
  border-radius: 0.6rem;
  flex-shrink: 0;
  position: relative;

  img {
    object-fit: cover;
    height: 100%;
  }
}

@each $name, $color in $colorsByName {
  .chip--#{$name} {
    background-color: $color;
  }
}

@each $name, $color in $colorUse {
  .chip--#{$name} {
    background-color: $color;
  }

  .chip__icon--#{$name} {
    color: $color;
  }
}

@each $status, $background, $border in $chipStatusColors {
  .chip--#{$status} {
    background-color: $background;
  }

  .chip--#{$status}:not(.chip--interaction) {
    border-color: $border;
  }
}

.chip.chip--disabled {
  background-color: var(--color-dark-10);
  color: var(--color-dark-50);

  .chip__interaction {
    color: var(--color-dark-50);
  }
}
