.channel-activate {
  margin-top: 2rem;
}

.channel-activate__inputs {
  flex-shrink: 0;
}

.channel-activate__date {
  .checkbox {
    margin-bottom: 0.8rem;
    margin-right: 0.4rem;
  }

  .modal & {
    width: 100%;
  }
}

.channel-activate__caption {
  margin-bottom: 1.6rem;
}

.channel-activate__group {
  & + & {
    margin-left: 3.6rem;
  }

  .activate-toggle {
    margin-top: 1.4rem;
  }

  .modal & + & {
    flex-grow: 1;
  }
}
