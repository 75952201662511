@import '../../styles/utils/mq';

.list-item.file-list-item {
  padding-right: 0.8rem;
  font-size: 1.2rem;
  border-radius: 0.4rem;

  @include mq($from: m) {
    font-size: var(--font-size-default);

    & + .list-item {
      margin-top: 0.8rem;
    }

    .button,
    .button.button--small {
      letter-spacing: 0;
    }
  }

  @include mq($until: m) {
    .button,
    .button.button--small {
      padding: 0 0.4rem;
      height: 2.1rem;
      width: 2.4rem;
      min-width: 2.4rem;
      letter-spacing: 0;
    }
  }
}

.file-list-item__text {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  user-select: none;
}

.file-list-item__chip {
  min-width: 8.6rem;

  .chip__inner {
    justify-content: center;
    flex-direction: column;
    display: inline-flex;
  }
}
