.terms-form__form {
  display: flex;
  align-items: flex-end;

  .input {
    margin: 0 1.2rem;
  }

  .button {
    margin-left: auto;
  }
}

.terms-form__error {
  padding-top: 3.8rem;
}
