@import '../../styles/utils/mq';
@import '../../styles/utils/z-index';
@import '../../styles/utils/hide-scrollbar';
@import '../../styles/fonts/font-mixins';

.modal {
  position: absolute;
  border: 2px solid var(--color-dark-20);
  background: var(--color-white);
  border-radius: var(--border-radius-default);
  outline: none;
  max-width: 40rem;
  width: 98%;
  bottom: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
}

.modal--dark {
  background-color: var(--color-dark);
  color: var(--color-white);
  border: 0;
}

.modal--wide {
  max-width: 60rem;
  max-height: calc(100vh - 4.8rem);
}

.modal--extra-wide {
  max-width: 80rem;
  max-height: calc(100vh - 4.8rem);
}

.modal--full,
.modal--bottom-sheet,
.modal--hidden-sidebar {
  max-width: calc(100vw - (2 * var(--modal-spacer)));
  bottom: var(--modal-spacer);
  left: var(--modal-spacer);
  right: var(--modal-spacer);
  top: var(--modal-spacer);
  width: auto;
  transform: translate(0, 0);
  overflow-x: hidden;
  z-index: z('modal');
}

.modal--bottom-sheet,
.modal--hidden-sidebar {
  bottom: 0;
  border-bottom: 0;
  border-radius: var(--border-radius-default) var(--border-radius-default) 0 0;
  transform: translateY(200%);
  animation: fromBottom 0.6s forwards;
}

@keyframes fromBottom {
  from {
    opacity: 0;
    transform: translateY(200%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal--hidden-sidebar {
  border-radius: var(--border-radius-default) 0 0 0;
  transform: translateX(200%);
  animation: fromRight 0.6s forwards;
  right: 0;
  padding: 0;
  max-width: 100vw;

  @include mq($from: m) {
    left: auto;
    top: calc(var(--header-height) + 1.6rem);
    width: calc(100vw - (2 * var(--modal-spacer)));
    max-width: 80rem;
  }

  @include mq($from: l) {
    max-width: 94rem;
    margin-right: 0;
  }
}

@keyframes fromRight {
  from {
    opacity: 0;
    transform: translateX(200%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.modal--terms {
  padding-top: 0;
  max-height: 78rem;
}

.modal__overlay {
  z-index: z('overlay');
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.75);

  .body--login & {
    background-color: rgba(255, 255, 255, 0.3);
  }
}

.modal__header {
  position: sticky;
  top: 0;
  display: flex;
  padding: var(--modal-spacer);
  padding-left: 0;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  z-index: z('close');
  border-radius: var(--border-radius-default) var(--border-radius-default) 0 0;
  height: var(--modal-header-height);

  &--border {
    border-bottom: 1px solid var(--color-dark-20);
  }

  .modal--dark & {
    background-color: var(--color-dark);
  }

  @include mq($from: m) {
    padding: 2rem 1rem 1rem 2rem;
  }

  @media (max-height: 630px) {
    padding: 0;
  }
}

.modal__content {
  position: relative;
  padding: 0 var(--modal-spacer) 1.6rem;

  .modal--full & {
    height: 100%;
  }

  .modal--bottom-sheet & {
    padding-bottom: 0;
    padding-top: 0;
  }

  .modal--disable-scroll & {
    overflow: hidden;
  }

  .modal--hidden-sidebar & {
    min-height: 100%;
  }

  @include mq($from: m) {
    padding: 0 2rem 1.6rem;
  }
}

.modal__content--hide-scrollbars {
  @include hide-scrollbar();
}

.modal__close {
  color: var(--color-dark-50);
  display: flex;
  padding: 1.2rem;
}

.modal__text {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  & + & {
    margin-top: 1.6rem;
  }

  p {
    font-size: 1.3rem;
  }
}

.modal__caption {
  @include font-caption(var(--color-primary));

  .modal__text + & {
    margin-top: 2.4rem;
  }
}

.modal__caption--black-50,
.modal__text--black-50 {
  color: var(--color-dark-50);
}

.modal__controls {
  display: flex;
  justify-content: space-between;
  margin-top: 3.2rem;
}

.modal__copied {
  color: var(--color-primary);
  font-weight: var(--font-weight-black);
  margin-bottom: 0.8rem;
  margin-top: 0.8rem;
}

.modal__share-label {
  @include font-caption(var(--color-primary));
}

.modal__form {
  .modal__text + & {
    margin-top: 1.6rem;
  }
}

.modal__form--flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  > div:first-child {
    flex-grow: 1;
    margin-right: 1.2rem;
  }

  .modal__text + & {
    margin-top: 4.8rem;
  }
}

.modal__list {
  margin-bottom: 4.4rem;
}

.modal__fixed {
  background-color: var(--color-white);
  position: sticky;
  z-index: z('sticky');
  padding: 1.6rem 0;
}

.modal__fixed--bottom {
  bottom: -1.6rem;
}

.modal__fixed--top {
  top: -1.6rem;
}

.modal__fixed-footer {
  position: fixed;
  bottom: 0;
  background-color: var(--color-white);
  z-index: z('sticky');
  padding: 1.6rem;
  border-top: 1px solid var(--color-dark-20);
  width: 100%;
  padding-right: 2rem;
  left: 0;
  right: 0;

  @include mq($from: s) {
    display: flex;
    justify-content: flex-end;
  }
}

.modal__fixed-footer .button + .button {
  margin: 0.8rem 0;
  @include mq($from: s) {
    margin: 0 0.8rem;
  }
}
