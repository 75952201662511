@import '../../styles/utils/list-reset';
@import '../../styles/utils/mq';

.list-item-nested {
  @include list-reset();

  & + & {
    margin-top: 0.2rem;
  }
}

.list-item-nested__button {
  width: 100%;
  text-align: left;
  padding: 0 0.8rem;

  &:hover,
  &:focus {
    background-color: var(--color-secondary-25);
  }

  @include mq($from: m) {
    padding: 0 1.2rem;
  }
}

.list-item-nested__title {
  padding: calc(var(--spacer) * 4) 0;

  @include mq($from: m) {
    padding: calc(var(--spacer) * 2) 0;
  }
}

.list-item-nested__content {
  margin-right: 0.8rem;
}
