@import '../../styles/utils/mq';

.filter-bar {
  position: relative;
}

.filter-bar__button {
  padding: 0.8rem 3.6rem 0.8rem 0.6rem;
  text-align: left;
  height: auto;
  min-height: 3.5rem;
  line-height: 2.2rem;
  cursor: pointer;
  transition-property: border-color;

  .chip {
    margin: 0.2rem 0.4rem;
    max-width: 16rem;
  }

  .chip__interaction {
    font-size: 1rem;
  }

  &:hover {
    border-color: var(--color-primary);
  }

  @include mq($from: m) {
    padding: 0.8rem 3.6rem 0.8rem 0.6rem;
  }
}

.filter-bar__items {
  margin: -0.2rem 0;
}

.filter-bar__icon {
  position: absolute;
  right: 0.8rem;
  top: 45%;
  transform: translateY(-50%);
  font-size: 2rem;
}

.filter-bar__reset {
  text-decoration: underline;
  position: absolute;
  right: 0;
  top: -1rem;
  transform: translateY(-100%);

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.filter-bar__placeholder {
  color: var(--color-dark-50);
  text-transform: uppercase;
  line-height: 1;
  padding-left: 0.4rem;
}
