.upload-field__button {
  cursor: pointer;

  .upload-field--disabled & {
    cursor: not-allowed;
    opacity: 0.6;
  }
}

.upload-field__value {
  font-size: 1rem;
  margin-top: 0.4rem;
  max-width: 19rem;

  & + .input__error {
    margin-top: 4.4rem;
  }
}

.upload-field__detail {
  margin-right: 0.4rem;
}

.upload-field__value {
  margin-top: 0.4rem;
  position: absolute;
}
