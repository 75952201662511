@import '../../styles/utils/mq';
@import '../../styles/utils/z-index';

.action-panel {
  background-color: var(--color-white);
  box-shadow: 2px 10px 14px 0 #b8b8b8;
  padding: 2.8rem;
  max-width: 60rem;
  transform: translate(200%, 0);
  transition-property: transform;
  transition-duration: 0.6s;
  z-index: z('sidebar');
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;

  .page__grid & {
    grid-column: 3;
  }

  .layout & {
    right: -0.8rem;
  }

  @include mq($from: m) {
    transform: translate(0, 0);
    position: relative;
    top: auto;
    bottom: auto;
    right: auto;
    max-width: 100%;
    height: 100%;
    z-index: z('panel');

    .layout & {
      right: auto;
    }
    &--error {
      background-color: var(--color-warning-25);
    }
  }

  @include mq($from: l) {
    padding: 4.2rem 6.4rem;
  }

  @include mq($from: xl) {
    margin-right: calc(-1 * calc((100vw - 144rem) / 2));
  }
}

.action-panel--open {
  transform: translate(0, 0);
}

.action-panel__close {
  animation: fade-in 0.6s;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;
  opacity: 0;
  z-index: z('close');
  float: right;
}

.action-panel__content {
  max-width: 60rem;
  overflow-x: hidden;
  position: sticky;
  top: calc(var(--header-height) + 0.8rem);

  .body--super-admin & {
    top: calc(var(--header-height) + 0.8rem + var(--status-bar-height));
  }

  @include mq($from: xl) {
    max-width: calc(144rem * 5 / 12 - 2 * 3.2rem);
  }
}
