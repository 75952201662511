@import '../../styles/fonts/font-mixins';
@import '../../styles/utils/list-reset';
@import '../../styles/utils/mq';

.video-ticket__wrap {
  @include list-reset();
  position: relative;

  & + & {
    margin-top: 0.8rem;
  }
}

.video-ticket__wrap--editable,
.video-ticket__wrap--selectable {
  padding-left: 3.5rem;

  .list & {
    margin-left: -3.5rem;
  }
}

.video-ticket {
  background-color: var(--color-white);
  position: relative;
  z-index: 2;
  border: 2px solid var(--color-dark-10);
  border-radius: var(--border-radius-default);
  padding: 0.4rem;
  transition-property: border-color;

  .video-ticket__wrap:focus &,
  .video-ticket__wrap--selected & {
    border: 2px solid var(--color-primary);
  }

  .video-ticket__wrap:hover & {
    border-color: var(--color-dark-10);
    outline: 1px solid var(--color-dark-10);
  }

  .video-ticket__wrap:focus:hover & {
    border-color: var(--color-primary-50);
    outline: 0;
  }
}

.video-ticket--draft {
  overflow: hidden;
  border-top-color: var(--color-warning-50);
}

.video-ticket__inner {
  display: flex;
  justify-content: space-between;

  .video-ticket--draft &::before {
    border-top: 0.4rem solid var(--color-warning-50);
    width: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

.video-ticket__title {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 95%;
  overflow-x: hidden;
}

.video-ticket__title {
  font-size: 1.2rem;
  color: var(--color-dark-50);
  margin-bottom: 1.2rem;
}

.video-ticket__content {
  margin-left: 0.8rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0.4rem 0.8rem 0.4rem 0;
  min-width: 0;
}

.video-ticket__channels {
  margin-top: auto;
}

.video-ticket__channel-list {
  padding: 0;
  display: flex;
  margin-top: 0.4rem;
}

.video-ticket__channel {
  @include list-reset();

  vertical-align: top;
  max-height: 1.9rem;

  & + & {
    margin-left: 0.8rem;
  }
}

.video-ticket__image {
  overflow: hidden;
  height: 15rem;
  width: 10rem;
  border-radius: 0.6rem;
  flex-shrink: 0;
  position: relative;
  background-color: var(--color-dark-20);

  img {
    object-fit: cover;
    height: 100%;
  }

  .video-ticket--channel & {
    background: var(--gradient-light);
    border: 1px solid var(--color-primary-25);

    .lazyimage {
      width: 9rem;
      height: 14.2rem;
      margin: auto;
      margin-top: 0.3rem;
      border-radius: 0.4rem;
    }
  }
}

.video-ticket__language-button {
  background-color: rgba(255, 255, 255, 0.8);
  width: 2.7rem;
  height: 2.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  transition-property: background-color;

  .video-ticket__image & {
    position: absolute;
    left: 0.8rem;
    top: 0.8rem;
  }
}

.video-ticket__language {
  font-size: 1.2rem;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  line-height: 1;

  &.icon {
    font-size: 1.5rem;
  }
}

.video-ticket__meta {
  display: flex;
  flex-direction: column;
  padding: 0.4rem;
  min-width: 26.7rem;

  .status {
    align-self: flex-end;
    margin-bottom: 0.8rem;
  }
}

.video-ticket__meta-inner {
  border-left: 1px solid var(--color-dark-20);
  padding-left: 0.8rem;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.video-ticket__meta-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.video-ticket__specs {
  font-size: 1rem;
  color: var(--color-dark-50);
  line-height: 1;
  display: flex;

  dt {
    font-weight: var(--font-weight-black);
    margin-bottom: 0.4rem;
  }

  dd {
    margin-left: 0;
  }
}

.video-ticket__specs-item {
  & + & {
    margin-left: 1.6rem;
    border-left: 1px solid var(--color-dark-20);
    padding-left: 1.6rem;
  }
}

.video-ticket__updated-at {
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  color: var(--color-dark-50);
  line-height: 1;
  align-items: center;

  .avatar {
    margin-right: 0.4rem;
  }

  .avatar__content {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.85rem;
    line-height: 1.6rem;
  }
}

.video-ticket__updated-title {
  font-weight: var(--font-weight-bold);
  width: 100%;
  margin-bottom: 0.4rem;
}

.video-ticket__controls {
  align-items: flex-end;
}

.video-ticket__controls-item {
  &:first-child {
    display: flex;

    > div + div {
      margin-left: 0.8rem;
    }
  }

  .activate-toggle {
    display: flex;
    justify-content: flex-end;
  }

  .video-visibility-toggle__desc {
    top: -2rem;
    left: 1rem;
  }
}

.video-ticket__selectable {
  position: absolute;
  left: 0.8rem;
  top: 1.4rem;
  z-index: 3;

  .checkbox {
    padding: 0;
  }

  .video-ticket__wrap--editable:hover & .checkbox__indicator {
    border-color: var(--color-primary-50);
    background-color: var(--color-primary-50);
  }

  .video-ticket__wrap--editable:hover & .checkbox__indicator-icon {
    color: var(--color-primary);
  }
}

.video-ticket__button {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: var(--color-primary);
  z-index: 1;
  width: 4rem;
  border-radius: var(--border-radius-default) 0 0 var(--border-radius-default);
  padding: 0.8rem 0;
  color: var(--color-white);
  text-transform: uppercase;
  letter-spacing: 0.13rem;
  font-size: 1.2rem;
  text-align: left;
  transform: translateX(100%);
  transition-property: all;

  .video-ticket__wrap--editable:hover &,
  .video-ticket__wrap--editable:focus {
    transform: translateX(0);
  }
}

.video-ticket__button-inner {
  width: 3.5rem;
}

.video-ticket__button-text {
  transform: rotate(-90deg) translateX(50%);
}
