@import '../../styles/utils/mq';
@import '../../styles/utils/z-index';

.statistics__controls {
  margin-top: 1.6rem;
  margin-bottom: 2.4rem;
}

.statistics__controls-inner {
  display: flex;
  justify-content: space-between;
}

.statistics__chart-center {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.statistics__chart {
  padding: 2.4rem;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 1.6rem;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
}

.statistics__chart-inner {
  width: 100%;
  height: 30rem;
}

.statistics__table {
  margin-bottom: 10rem;
  font-size: 1.3rem;
  line-height: 1.9rem;

  @media (max-width: 710px) {
    overflow-x: auto;
    padding-top: 1.6rem;
  }
}

.statistics__table-inner {
  min-width: 76rem;
}

.statistics__table-filter {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;

  .search {
    width: 100%;
    margin: 0.4rem;
  }

  .select {
    width: 100%;
    flex-shrink: 1;
    flex-grow: 1;
    margin: 0.4rem;
  }

  & + & {
    margin-top: 1.6rem;
  }

  &:last-child {
    margin-bottom: 5.6rem;
  }

  &:first-child {
    margin: 0 -0.4rem;
  }

  @include mq($from: m) {
    flex-wrap: nowrap;

    .search {
      width: 30%;
      margin: 0 0 0.01rem 0;
    }

    .select {
      width: 23%;
      margin: 0 0 0 0.8rem;
    }
  }
}

.statistics__table-title {
  margin-bottom: 2.4rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .headline {
    margin-bottom: 1.2rem;
  }
}

.statistics__tr {
  width: 100% !important;
  cursor: pointer;

  .statistics__totals & {
    cursor: default;
  }

  .statistics__tbody & {
    padding: 0.2rem;
  }
}

.statistics__tr-inner {
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid transparent;

  .statistics__thead & {
    box-shadow: 0 0 10px 1px rgb(0 0 0 / 20%);
    z-index: z('sticky');
  }

  .statistics__totals & {
    background-color: var(--color-secondary-50);
    color: var(--color-dark-50);
    border-radius: 0 0 0.8rem 0.8rem;
  }

  .statistics__tbody .statistics__tr:hover & {
    outline: 1px solid var(--color-secondary) !important;
  }
}

.statistics__th {
  padding: 0.8rem 1.2rem;
  font-weight: var(--font-weight-bold);
}

.statistics__td {
  padding: 0.8rem 1.2rem;

  .statistics__totals & {
    padding: 0.4rem 1.2rem;
  }
}

.statistics__td-inner {
  display: flex;
}

.statistics__tbody {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 3rem;
    width: 100%;
    background: var(--color-white);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(234, 204, 248, 0) 100%
    );
    pointer-events: none;
  }
}

.statistics__fixed-size-list {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-y: scroll !important;
  overflow-x: hidden !important;

  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}

.statistics__smybol {
  font-size: 0.8em;
  margin-right: 0.4rem;
}

.statistics__smybol--sum {
  font-size: 0.7em;
}

.statistics__arrow {
  margin-left: 1.2rem;
  color: var(--color-primary);
}

.statistics__title {
  display: inline-flex;
  align-items: center;
  line-height: 1;
}

.statistics__totals {
  margin-bottom: 0.4rem;
}

.statistics__remove-icon {
  color: var(--color-warning);
  line-height: 1.25;
  margin-top: 0.1rem;
}

.statistics__cell-value {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.statistics__name {
  display: flex;
  align-items: center;

  svg {
    margin-top: -0.2rem;
  }
}

.statistics__name--black-50 {
  color: var(--color-dark-50);
}

.statistics__name--italic {
  font-style: italic;
}

.statistics__marker {
  margin-right: 0.4rem;
}

.statistics__status {
  padding: 0.8rem 1.2rem;
}

.statistics__status-icon {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 1px solid var(--color-dark);
  display: block;

  &--live {
    background-color: var(--color-confirm);
    border-color: transparent;
  }

  &--transparent {
    background-color: transparent;
    border-color: transparent;
  }
}
