@import '../../../../styles/utils/mq';

.filter-detail {
  @include mq($from: m) {
    padding: 0 calc(var(--spacer) * 3);

    .select-interactive__menu {
      margin: 0 calc(-1 * calc(var(--spacer) * 3));
    }
  }
}
