@import '../../styles/utils/list-reset';
.list-item-simple {
  @include list-reset();
  padding: 1.2rem;
  border: 1px solid var(--color-dark-50);
  border-radius: var(--border-radius-default);
}

.list-item-simple__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  .headline {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.list-item-simple__header-right {
  display: flex;
  align-items: center;
}
