.file-manager__header {
  background-color: var(--color-dark);
  color: var(--color-white);
  border-radius: 0 var(--border-radius-default) 0 0;
  padding: 0.4rem 0.8rem;
  line-height: 1;
}

.file-manager__title {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: var(--font-weight-black);
  letter-spacing: 0.05em;
  flex-grow: 1;
}

.file-manager__progress {
  font-weight: var(--font-weight-normal);
  margin-left: 1.2rem;
}

.file-manager__button {
  height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-manager__body {
  border: 1px solid var(--color-dark-10);
  background-color: var(--color-white);
}

.file-manager__body-inner {
  padding: 1.2rem 0.8rem;
  font-size: 1.2rem;
  max-height: 12rem;
  overflow-y: auto;
}

.file-manager__empty {
  color: var(--color-dark-50);
}

.file-manager__list {
  margin: 0;
  padding: 0;
}
