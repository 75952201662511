@import '../../styles/utils/mq';

.list-item.add-button {
  background-color: var(--color-lavender-25);
  display: block;
  text-align: left;
  width: 100%;
  padding: 1.2rem;
  transition-property: background-color;
  border-radius: 0.4rem;

  &:hover {
    background-color: var(--color-lavender-50);
  }

  @include mq($until: m) {
    padding: 0.8rem;

    .list-item__title {
      font-size: 1.2rem;
      letter-spacing: 0;
    }
    .list-item__subtitle {
      font-size: 1rem;
      line-height: 1em;
    }
  }
}

.add-button__button {
  color: var(--color-lavender);

  span {
    margin-right: var(--spacer);
  }

  @include mq($until: m) {
    font-size: 1.2rem;
  }
}
