.validate-password-input__input {
  position: relative;
}

.validate-password-input__password-toggle-btn {
  position: absolute;
  top: 2.2rem;
  right: 1rem;
  height: 2.4rem;
  width: 2.4rem;
  color: var(--color-dark-50);

  svg {
    fill: currentColor;
  }

  &--visible {
    color: var(--color-warning);
  }
}
