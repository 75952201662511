@import '../../styles/utils/z-index';
@import '../../styles/utils/mq';
@import '../../styles/fonts/font-mixins';

.video-detail {
  @include mq($from: m) {
    --video-player-width: 12rem;
    --video-player-height: 20.5rem;

    .localisation-tabs__title {
      @include font-headline(3);

      text-transform: none;
    }

    .localisation-tabs__content {
      margin-top: 2.2rem;
    }
  }
}

.grid-layout.video-detail__grid {
  grid-template-columns: var(--video-player-width) 1fr;
  grid-template-rows: auto minmax(5.6rem, 1fr) minmax(5.6rem, 1fr);
  grid-gap: 0;

  .caption {
    text-transform: none;
    letter-spacing: 0.02rem;
  }
}

.grid-layout.video-detail__panels {
  grid-template-columns: minmax(0, 0.95fr) minmax(0, 1fr);
  grid-template-rows: 1fr;
  grid-gap: 0;
  margin-top: 0;
  min-height: 100%;

  .sidebar & {
    min-height: calc(100vh - var(--header-height) - 1.6rem);
  }

  .body--superadmin .sidebar & {
    min-height: calc(
      100vh - var(--header-height) - var(--status-bar-height) - 1.6rem
    );
  }
}

.video-detail__update,
.video-detail__channels {
  @include mq($from: m) {
    padding: 1.6rem 1.6rem 1.6rem 2.8rem;
  }
}

.video-detail__player {
  grid-row: span 3;
  max-height: var(--video-player-height);
}

.video-detail__controls {
  grid-column: 2;
  grid-row: 1;
}

.video-detail__meta {
  grid-column: 2;
  grid-row: 2;
}

.video-detail__updated {
  grid-column: 2;
  grid-row: 3;

  .label-text__text {
    margin-top: 0;
    font-size: 1rem;
  }
}

.video-detail__specs {
  font-size: 1rem;
  display: flex;
  line-height: 1;

  dt {
    font-weight: var(--font-weight-black);
    margin-bottom: 0.4rem;
    color: var(--color-dark-50);
    margin-right: 0.4rem;
  }

  dd {
    line-height: 1;
    margin-left: 0;
    font-size: 1rem;
  }
}

.video-detail__secondary {
  background-color: var(--color-secondary-25);
  border: 1px solid var(--color-secondary-50);
  border-width: 1px 0;

  @include mq($from: m) {
    border: 0;
    height: 100%;
    display: flex;
    flex-direction: column;

    .product-info {
      height: 100%;
    }

    footer {
      position: sticky;
      bottom: 0.8rem;
      margin-top: auto;
    }
  }
}

.video-detail__secondary--warning {
  background-color: var(--color-warning-10);
  border-color: var(--color-warning-50);
}

.video-detail__secondary--success {
  background-color: var(--color-confirm-10);
  border-color: var(--color-confirm-50);
}

.video-detail__overlay-wrap {
  position: relative;

  @include mq($from: m) {
    height: 100%;
  }
}

.video-detail__overlay {
  z-index: z('overlay');
  position: absolute;
  top: 1px;
  bottom: 1px;
  right: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.75);

  .loader {
    margin-top: 1.6rem;
  }
}

.video-detail__type-button {
  position: relative;
}

.video-detail__success-chip {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-100%);
}

.video-detail__product-footer-item {
  background-color: var(--color-secondary-25);
  border-radius: var(--border-radius-default);

  .video-detail__secondary--warning & {
    background-color: var(--color-warning-10);
  }

  .video-detail__secondary--success & {
    background-color: var(--color-confirm-10);
  }
}

.video-detail__encoding {
  width: var(--video-player-width);
  height: var(--video-player-height);
  position: relative;
  border: 1px solid var(--color-dark-20);
  border-radius: var(--border-radius-default);
}

.video-detail__product-info {
  @include mq($from: m) {
    .localisation-tabs__title-wrap {
      // place bottom of video player, height of video player - height of controls - spacer of controls
      height: calc(var(--video-player-height) - 4.5rem - (var(--spacer) * 2));
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
    }
  }
}
