@import '../../../styles/utils/mq';
@import '../../../styles/utils/z-index';

$layout-grid-max-width: 144rem;

.layout-grid {
  display: grid;
  align-content: space-between;
  position: relative;
  grid-template-rows: auto 1fr auto auto;
  grid-template-columns: 1fr minmax(auto, 100vw);
  grid-template-areas:
    'header sidebar'
    'main sidebar'
    'footer sidebar';
  overflow: hidden;
  padding: 0 var(--body-margin);
  min-height: calc(100vh - var(--header-height));

  .layout & {
    flex-grow: 1;
  }

  .body--super-admin & {
    min-height: calc(100vh - var(--header-height) - var(--status-bar-height));
  }

  @include mq($from: m) {
    grid-template-columns: 1fr minmax(0, 6fr) minmax(0, 5fr);
    grid-template-rows: 1.6rem auto 1fr auto;
    grid-template-areas:
      '. .       sidebar'
      '. header  sidebar'
      '. main    sidebar'
      '. footer  sidebar';
  }

  @include mq($from: xl) {
    grid-template-rows: 2.6rem auto 1fr auto;
    justify-content: center;
  }
}

.layout-grid__item {
  min-width: 0;
}

.layout-grid__item-inner {
  height: 100%;
}

.layout-grid__item--footer,
.layout-grid__item--header,
.layout-grid__item--main {
  @include mq($from: m) {
    align-self: start;
  }
}

.layout-grid__item--header {
  grid-area: sidebar-start / sidebar-start / header-end / header-end;

  @include mq($from: m) {
    grid-area: header;
    padding-right: 1.6rem;
  }
}
.layout-grid__item--sidebar {
  grid-area: sidebar;
  @include mq($from: m) {
  }

  @include mq($from: m) {
    margin-right: -1.6rem;
  }

  @include mq($from: xl) {
    margin-right: calc((100vw - 144rem) / -2);

    .layout-grid__item__inner {
      margin: 0 calc((100vw - 144rem) / 2);
    }
  }
}
.layout-grid__item--main {
  z-index: z('content');
  grid-area: main-start / sidebar-start / main-end / main-end;

  @include mq($from: m) {
    grid-area: main;
    padding-right: 1.6rem;
  }
}
.layout-grid__item--footer {
  padding-top: 3.2rem;
  grid-area: footer-start / sidebar-start / footer-end / footer-end;

  @include mq($from: m) {
    grid-area: footer;
    padding: 1.6rem 1.6rem 0;
  }
}
