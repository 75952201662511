.notifications-filter {
  text-align: right;
  margin-bottom: 0.5rem;
}

.notifications-filter__label {
  font-size: 1.4rem;
  margin-right: 1rem;
}

.notifications-filter__button {
  border: 1px solid var(--color-secondary);
  border-radius: 4px;
  padding: 0 0.8rem;
  font-size: 1.4rem;

  &--active {
    background-color: var(--color-secondary);
  }

  & + & {
    margin-left: 0.4rem;
  }
}
