@import '../../styles/fonts/font-mixins';
@import '../../styles/abstracts/variables';

.list__controls {
  border-bottom: 1px solid var(--color-dark-20);
  padding-bottom: 0.8rem;
  margin-bottom: 0.8rem;
  display: flex;
  justify-content: space-between;

  .checkbox {
    margin-left: 1.2rem;
  }
}

.list__count {
  @include font-body-small(var(--color-dark));
}

.list__items {
  margin: 0;
  padding: 0;
  flex-grow: 1;

  > li + li {
    margin-top: 0.4rem;
  }
}

.list__item {
  & + & {
    margin-top: 0.4rem;
  }
}

.list__more {
  text-align: center;

  .button {
    margin-top: 3.2rem;
  }

  .loader {
    margin-top: 1.6rem;
  }
}
.query-list {
  .list__more {
    position: relative;

    .loader {
      margin-top: 0;
      position: absolute;
      top: 0.8rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

@each $multiplicator in $spacerMultiplicators {
  .list__controls {
    .spacer--top-minus-#{$multiplicator} & {
      padding-top: calc($spacer * $multiplicator);
    }
    .spacer--right-minus-#{$multiplicator} & {
      padding-right: calc($spacer * $multiplicator);
    }
    .spacer--bottom-minus-#{$multiplicator} & {
      padding-bottom: calc($spacer * $multiplicator);
    }
    .spacer--left-minus-#{$multiplicator} & {
      padding-left: calc($spacer * $multiplicator);
    }
  }
}
