@import '../../styles/utils/mq';

.settings {
  .form__block:last-of-type {
    margin-bottom: 14rem;
  }
}

.settings__form {
  margin-top: 1.6rem;
}

.settings__inline-form {
  margin-top: 1.6rem;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: column;

  &:first-of-type {
    margin-top: 0;
  }

  @include mq($from: m) {
    flex-direction: row;
  }
}

.settings__headline {
  margin-bottom: 1.6rem;

  .icon-button {
    margin-left: 0.6rem;
  }
}

.settings__headline--first {
  margin-top: 4rem;
}

.settings__block {
  margin-bottom: 2.4rem;
}

.settings__block--inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: -0.4rem -0.4rem 2rem;

  > * {
    margin: 0.4rem;
  }

  @include mq($from: m) {
    flex-wrap: nowrap;
    margin: 0 0 2.4rem 0;

    > * {
      margin: 0;
    }

    > * + * {
      margin: 0 0 0 0.8rem;
    }
  }
}

.settings__block--end {
  margin-left: auto;
}

.settings__block--25 {
  width: 100%;

  @include mq($from: m) {
    width: 25%;
  }
}

.settings__block--50 {
  width: 100%;

  @include mq($from: m) {
    width: 50%;
  }
}

.settings__block--75 {
  width: 100%;

  @include mq($from: m) {
    width: 75%;
  }
}

.settings__list-item {
  margin-bottom: 2rem;

  @include mq($from: m) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.settings__list-item-right {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mq($from: m) {
    flex-grow: 1;
  }
}

.settings__text {
  color: var(--color-dark);

  &--warning {
    color: var(--color-dark-50);
  }
}

.settings__list-item-actions {
  display: flex;
  align-items: center;
}

.settings__list-item-delete {
  margin-left: 0.4rem;
}

.settings__refLinks-right {
  margin-top: 1.6rem;
  display: flex;

  @include mq($from: m) {
    margin-left: 0.8rem;
  }
}

.settings__list {
  padding: 0;
  margin: 0;
  margin-bottom: 4rem;
}
