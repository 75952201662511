@import '../../styles/utils/mq';

.ref-list-item__date {
  margin-right: 0.8rem;
  font-size: 1.2rem;
  color: var(--color-dark);
}

.ref-list-item__body-extended {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;

  @include mq($from: m) {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.ref-list-item__meta {
  margin-top: 1.6rem;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  flex-wrap: wrap;

  > div {
    margin: 0 0.8rem 0.8rem 0;
  }

  dd {
    margin: 0;
    font-size: 1.2rem;
    color: var(--color-dark);
    line-height: 1em;
  }

  @include mq($from: m) {
    margin-right: 1.6rem;

    > div {
      margin: 0;
    }
  }
}

.ref-list-item__tooltip {
  margin-left: auto;
}

.ref-list-item__installations-count {
  color: var(--color-dark);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ref-list-item__installations-icon {
  background-color: var(--color-confirm);
  border-radius: var(--border-radius-default);
  width: 2.4rem;
  height: 2.4rem;
  color: var(--color-dark);
  font-size: 1.2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.ref-list-item__installations-text {
  margin-right: 0.8rem;
  font-size: 1.2rem;
  line-height: 1;
}

.ref-list-item__controls {
  display: flex;
}

.ref-list-item__installations {
  display: flex;
  margin-top: 1.2rem;

  @include mq($from: m) {
    margin-top: -2.4rem;
  }
}

.ref-list-item__button {
  margin-left: 0.5rem;
}
