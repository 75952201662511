@import '../../styles/utils/mq';
@import '../../styles/utils/list-reset';

.list-item-select {
  @include list-reset();

  & + & {
    margin-top: 0.2rem;
  }
}

.list-item-select__select {
  .select__select {
    text-align: left;
  }

  @include mq($from: m) {
    .select__wrap {
      max-width: 32rem;
    }
  }
}

.list-item-select__checkbox {
  font-size: 1.2rem;
  line-height: 1.75;
  width: 100%;

  .checkbox,
  .checkbox__label {
    width: 100%;
    display: block;
  }

  @include mq($from: m) {
    line-height: 3rem;
  }
}

.list-item-select__option-wrap {
  .checkbox__wrap {
    width: 100%;
  }

  .list-item-select--chip & {
    border-radius: var(--border-radius-default);
    background-color: var(--color-white);
  }

  &:hover,
  &:focus {
    background-color: var(--color-secondary-25);
  }

  &.list-item-select__option-wrap--selected {
    background-color: var(--color-secondary-50);

    @include mq($from: m) {
      background-color: var(--color-secondary-75);
    }
  }
}

.list-item-select__option-wrap--selected {
  position: relative;
  z-index: 2;
}

.list-item-select__option {
  .checkbox__label {
    font-size: var(--font-size-default);
    line-height: 1.38em;
    min-height: 2.2rem;
    padding: calc(var(--spacer) * 4) 1.2rem calc(var(--spacer) * 4) 0;
    position: relative;
    align-items: center;
    letter-spacing: 0;
  }

  .list-item-select--chip & .checkbox__label {
    padding: calc(var(--spacer) * 2) calc(var(--spacer) * 2)
      calc(var(--spacer) * 2) 0;
  }

  .checkbox__indicator {
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-dark);
    background-color: transparent;
    border-color: transparent;
    margin-left: calc(var(--spacer) * 2);

    @include mq($from: m) {
      margin-left: var(--spacer);
    }
  }

  .checkbox__indicator-icon {
    color: var(--color-dark);
    opacity: 0.2;
  }

  &:hover .checkbox__indicator-icon,
  &:focus .checkbox__indicator-icon {
    opacity: 0.5;
  }

  .checkbox__input:checked ~ .checkbox__indicator {
    background-color: transparent;
  }
}
