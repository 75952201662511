.category-branch {
  color: var(--color-black-50);
  font-size: 1.2rem;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;

  .icon {
    font-size: 0.8rem;
  }
}

.label-text {
  .category-branch {
    color: var(--color-black-80);
    font-size: 1.6rem;
    line-height: var(--line-height-default);
  }
  .category-branch__item:nth-last-child(2),
  .category-branch__item:last-child {
    font-weight: var(--font-weight-bold);
    color: var(--color-black-80);
  }
}

.product-info .label-text {
  .category-branch {
    font-size: 1.2rem;
  }
}
