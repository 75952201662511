@import '../../styles/utils/list-reset';
@import '../../styles/fonts/font-mixins';

.video-list-item {
  @include list-reset();

  background-color: var(--color-white);
  border-radius: var(--border-radius-default);
  display: flex;
  padding: 0.4rem 1.6rem 0.4rem 0.4rem;
  box-shadow: inset 0 0 0 0.2rem var(--color-white);
  cursor: pointer;

  &:hover,
  &:focus {
    box-shadow: inset 0 0 0 0.2rem rgba(var(--color-secondary-rgb), 0.3);
  }

  & + &,
  .list-item + & {
    margin-top: 0.4rem;
  }
}

.video-list-item--active {
  box-shadow: inset 0 0 0 0.2rem var(--color-primary);
  cursor: auto;

  &:hover,
  &:focus {
    box-shadow: inset 0 0 0 0.2rem var(--color-primary);
  }
}

.video-list-item__detail {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: 1.6rem;
  padding: 1.6rem 0;
  min-width: 0;
}

.video-list-item__title {
  @include font-headline(3);
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 95%;
  overflow-x: hidden;
}

.video-list-item__subtitle {
  @include font-body-small(var(--color-dark-50));
}

.video-list-item__image {
  overflow: hidden;
  width: 10rem;
  border-radius: 0.6rem;
  flex-shrink: 0;
  position: relative;
}

.video-list-item__controls {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  padding: 1.6rem 0;
}

.video-list-item__arrow {
  font-size: 1.2rem;
}

.video-list-item__language {
  position: absolute;
  top: 0.4rem;
  left: 0.4rem;
  background-color: rgba(255, 255, 255, 0.8);
  height: 2.7rem;
  min-width: 2.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  border-radius: var(--border-radius-default);

  &--error {
    border: 1px solid var(--color-process);
    color: var(--color-process);
    background-color: var(--color-white);
  }
}

.video-list-item__tags {
  @include list-reset();

  display: flex;
  flex-wrap: wrap;
  margin: -0.2rem;
}

.video-list-item__tag-item {
  margin: 0.2rem;
}

.video-list-item__info {
  margin-top: auto;
}

.video-list-item__status {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.video-list-item__status-item {
  & + & {
    margin-top: 0.8rem;
  }
}
