@mixin font-body($color: var(--color-dark)) {
  font-size: var(--font-size-default);
  letter-spacing: 0.02rem;
  line-height: 1.68em;
  color: $color;
}
@mixin font-body-small($color: var(--color-dark)) {
  font-size: 1.2rem;
  letter-spacing: 0.02rem;
  line-height: 1.68em;
  color: $color;
}
@mixin caption($color) {
  font-weight: var(--font-weight-black);
  letter-spacing: 0.07rem;
  text-transform: uppercase;
  color: $color;
}
@mixin font-caption($color) {
  @include caption($color);
  font-size: 1rem;
}
@mixin font-caption-big($color) {
  @include caption($color);
  font-size: 1.6rem;
}
@mixin font-headline($level) {
  letter-spacing: 0.14rem;
  line-height: 1.3em;
  @if $level == 1 {
    font-size: 3.2rem;
  }
  @if $level == 2 {
    font-size: 2rem;
  }
  @if $level == 3 {
    font-size: 1.6rem;
  }
  @if $level == 4 {
    font-size: 1.6rem;
    color: var(--color-warning);
    font-weight: var(--font-weight-black);
  }
}
