@import '../../styles/fonts/font-mixins';
@import '../../styles/utils/svg';
@import '../../styles/utils/mq';
@import '../../styles/utils/z-index';

.select {
  position: relative;
}

.select__select {
  appearance: none;
  background-color: var(--color-white);
  border: 1px solid var(--color-dark-20);
  border-radius: var(--border-radius-default);
  color: var(--color-dark);
  height: 3.5rem;
  padding-left: 0.8rem;
  padding-right: 3.6rem;
  font-size: 1.2rem;
  display: block;
  width: 100%;
  position: relative;
  cursor: pointer;
  transition-property: all;
  margin: 0;
  text-align: left;

  &:focus {
    border-color: var(--color-primary);
  }

  &::placeholder {
    color: var(--color-dark-20);
  }

  .select--dark & {
    background-color: var(--color-dark);
    color: var(--color-white);
    border-color: hsl(0, 0%, 70%);
  }

  .select--dark &:hover {
    border-color: var(--color-white);
  }

  .select--dark &:focus {
    box-shadow: 0 0 0 1px var(--color-primary);
    border-color: var(--color-primary);
  }

  .select--error & {
    border-color: var(--color-warning);
  }
  .select--success & {
    border-color: var(--color-confirm);
  }

  .select--filter & {
    text-transform: uppercase;
    letter-spacing: 0.07em;
    border-color: var(--color-primary);
  }

  .select--multiple & {
    height: auto;
  }

  .select--icon & {
    padding-left: 2.6rem;
  }

  .select--loading & {
    padding-right: 6.2rem;
  }

  @include mq($from: s) {
    padding-left: 1.6rem;
    padding-right: 3.2rem;

    .select--icon & {
      padding-left: 3.4rem;
    }
  }
}

.select__select[disabled] {
  color: var(--color-dark-20);
  cursor: not-allowed;
}

.select__wrap {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    right: 1.6rem;
    top: 50%;
    transform: translateY(-50%);
    background-image: svg-url(
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" fill="#937ef3"><path d="M28.6 48.2l75 75 75-75 23 23-98 98.3-98-98.3z" /></svg>'
    );
    background-repeat: no-repeat;
    width: 1rem;
    height: 1rem;
    background-size: cover;
    pointer-events: none;
  }

  .select--dark &::after {
    background-image: svg-url(
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" fill="#7f7f7f"><path d="M28.6 48.2l75 75 75-75 23 23-98 98.3-98-98.3z" /></svg>'
    );
  }

  .loader {
    position: absolute;
    right: 3.2rem;
    bottom: 1.7rem;
    transform: translateY(50%);
  }
}

.select__label {
  @include font-caption(var(--color-dark-50));

  display: block;

  .select--dark & {
    color: var(--color-white);
  }
}

.select__reset {
  position: absolute;
  right: 3.2rem;
  top: 0.6rem;
  z-index: z('content');
  color: var(--color-primary);
}

.select__icon {
  position: absolute;
  left: 0.8rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: z('content');
  font-size: 1.4rem;

  .select--dark & {
    color: var(--color-white);
  }

  @include mq($from: s) {
    left: 1.6rem;
  }
}
