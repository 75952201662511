@import '../../styles/utils/mq';

.language-select--chip {
  display: inline-block;

  .select__wrap:after {
    content: none;
  }

  .open-select & {
    display: block;
  }

  .select__select {
    background-color: var(--color-secondary);
    border: none;
    height: 2.1rem;
    width: 2.4rem;
    font-size: 1.2rem;
    padding: 0 0.4rem;
    border-radius: 0.4rem;
    text-align: center;
  }
  @include mq($from: m) {
    .select__select {
      padding: 0 0.8rem;
      width: 2.8rem;
      height: 2.8rem;
    }
  }

  .select-interactive__fake-text {
    text-transform: uppercase;
  }
}
