@import '../../../styles/utils/mq';

.add-ref-link__form {
  margin-top: 1.6rem;
}

.add-ref-link__input {
  max-width: 24rem;
  margin-bottom: 3rem;
}

.add-ref-link__extend-button {
  display: flex;
  font-size: 1.2rem;

  &:focus {
    text-decoration: underline;
  }
}

.add-ref-link__extend-icon {
  width: 1em;
  margin-left: 1rem;

  svg {
    fill: currentColor;
  }
}

.ref-links__form-image {
  .image-uploader__preview-img {
    object-fit: contain;
    width: auto;
  }

  .image-uploader__drop-area {
    width: 23rem;
    height: 9rem;
    border-radius: 0;
    overflow: auto;
  }
}
