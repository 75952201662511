@import '../../styles/fonts/_font-mixins';

.label-text__text {
  margin-top: 0.6rem;
  max-width: 80rem;

  .sidebar & {
    max-width: 100%;
  }

  .label-text--collapsed & {
    overflow: hidden;
    height: 0;
    position: relative;

    &::after {
      content: '';
      width: 100%;
      height: 4rem;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0.7) 12%,
        rgba(255, 255, 255, 0) 100%
      );
      position: absolute;
      bottom: 0;
    }
  }

  dl div {
    display: flex;
  }

  dt {
    font-weight: var(--font-weight-bold);
    color: var(--color-dark-50);
    font-size: 1rem;
    letter-spacing: 0.06rem;
  }

  dd {
    margin-left: 0.8rem;
  }
}

.label-text__label {
  display: flex;
  align-items: center;
}

.label-text__icon {
  margin-left: 0.4rem;
  font-size: 1rem;
  display: block;
  transition-property: transform;
  transform: rotate(180deg);

  .label-text--collapsed & {
    margin-top: -0.2rem;
    transform: rotate(0);
  }
}
