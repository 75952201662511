@import '../../../styles/utils/mq';

.order-status-action-option {
  .select-interactive__option-inner {
    padding: calc(var(--spacer) * 2) 1.2rem calc(var(--spacer) * 2) 3.2rem;

    @include mq($from: m) {
      .select-interactive:not(.select-interactive--open-select) & {
        padding: 0.2rem 1.2rem 0.2rem 2.2rem;
      }

      .select-interactive--open-select & {
        padding: calc(var(--spacer) * 2) 1.2rem calc(var(--spacer) * 2) 3.2rem;
      }
    }
  }

  .select-interactive__label {
    display: flex;
    align-items: center;
  }

  .select-interactive__check {
    height: 3.4rem;
  }
}
