@import '../../styles/abstracts/variables';
@import '../../styles/utils/z-index';

.terms__version-wrap {
  position: fixed;
  top: 6rem;
  left: 2rem;
  z-index: 9;
  background-color: #fff;
  padding-right: 0.5rem;
}

.terms__footer {
  position: sticky;
  bottom: -1.6rem;
  background-color: var(--color-white);
  padding: 1.6rem;
  margin: 0 -1.6rem -1.6rem;
  border-top: 1px solid var(--color-dark-20);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 6rem;

  .page__landing & {
    bottom: -2rem;
  }
}

.terms__version {
  color: var(--color-dark-50);
  font-weight: var(--font-weight-normal);
  text-transform: none;
}

.terms__content {
  min-height: 8rem;
  padding-top: 4rem;

  .loader {
    margin: 4rem 0 2.4rem;
  }
}

.terms__public {
  margin-left: auto;
  display: flex;
  align-items: center;

  span {
    color: var(--color-dark-50);
    padding-right: 0.8rem;
    font-size: 1.2rem;
  }
}

.terms__error {
  padding: 1.6rem;
}
