@import '../../styles/abstracts/variables';

$animation-duration: 0.6s;

.loader {
  display: flex;
  justify-content: center;
  font-size: 3rem;
}

.loader--small {
  font-size: 1.5rem;
}

.loader__ring {
  width: 1em;
  height: 1em;
  border: 0.16em solid var(--color-secondary);
  border-radius: 50%;
  animation-duration: $animation-duration;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.loader__ring--right {
  animation-name: bounceRight;
}

.loader__ring--left {
  animation-name: bounceLeft;
}

@each $name, $color in $colorUse {
  .loader--#{$name} .loader__ring {
    border-color: $color;
  }
}

@keyframes bounceRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-0.26em);
  }
}

@keyframes bounceLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0.26em);
  }
}
