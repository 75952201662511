@each $name, $color, $textColor in $colors {
  .c-#{$name} {
    color: $color;
  }

  .bg-#{$name} {
    background-color: $color;
    color: $textColor;
  }
}

@each $name, $color in $colorUse {
  .c-#{$name} {
    color: $color;
  }

  .bg-#{$name} {
    background-color: $color;
  }
}

@each $name, $color in $colorsByName {
  .c-#{$name} {
    color: $color;
  }

  .bg-#{$name} {
    background-color: $color;
  }
}
