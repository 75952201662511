@import '../../styles/utils/mq';
@import '../../styles/utils/z-index';

.mediathek {
  --modalTop: 2.4rem;
  --modalBottom: 2.4rem;
  --mediaHeaderHeight: 20rem;
  --mediaFooterHeight: 6.8rem;

  max-width: 192rem;
  min-height: calc(
    100vh - var(--modalTop) - var(--modalBottom) - var(--mediaHeaderHeight) -
      var(--mediaFooterHeight)
  );
  padding-bottom: 14rem;

  .howto {
    margin: 0;
    margin-top: -3.2rem;
  }

  @include mq($from: s) {
    --mediaHeaderHeight: 14.8rem;
  }

  @include mq($from: m) {
    padding-bottom: 8rem;

    .grid & {
      margin-left: -1.6rem;
    }
  }

  @include mq($from: xl) {
    .grid & {
      margin-left: 0;
    }
  }
}

.mediathek__filter {
  margin: 2rem 0 0 0;

  @include mq($from: s) {
    justify-content: flex-start;
    align-items: flex-end;
    display: flex;

    .search {
      max-width: 24rem;
      margin-right: 1.6rem;
    }
    .button {
      margin-left: auto;
    }
  }

  @include mq($from: m) {
    .search {
      margin-right: 3.2rem;
    }
  }
}

.mediathek__items {
  margin: 0;
  padding: 0;

  @include mq($from: s) {
    margin: -0.8rem;
    display: flex;
    flex-wrap: wrap;
  }

  @include mq($from: l) {
    margin: -1.6rem;
  }
}

.mediathek__subtitle {
  color: var(--color-dark-50);
}

.mediathek__controls {
  font-size: 1.2rem;
  border-bottom: 1px solid var(--color-dark-20);
  display: flex;
  align-items: flex-end;
  position: sticky;
  top: calc(var(--mediaHeaderHeight) - 2px);
  z-index: z('sticky');
  padding-bottom: 0.8rem;
  margin-bottom: 3.2rem;

  .modal & {
    background-color: var(--color-white);
    padding: 1.6rem 3.2rem 0.8rem;
    margin: 0 -3.2rem 3.2rem;
  }

  @include mq($from: m) {
    .modal & {
      background-color: var(--color-white);
      padding: 1.6rem 6.4rem 0.8rem;
      margin: 0 -6.4rem 3.2rem;
    }
  }
}

.mediathek__count {
  line-height: 1;
}

.mediathek__list {
  margin: 0;
  padding: 0;
}

.mediathek__header {
  --mediaHeaderHeight: 20rem;

  position: sticky;
  top: 0;
  background-color: var(--color-white);
  height: var(--mediaHeaderHeight);
  z-index: z('sticky');
  padding: 1.6rem 3.2rem 0;
  margin: 0 -3.2rem;

  @include mq($from: s) {
    --mediaHeaderHeight: 14.8rem;
    height: var(--mediaHeaderHeight);
  }

  @include mq($from: m) {
    padding: 1.6rem 6.4rem 0;
    margin: 0 -6.4rem;
  }
}

.mediathek__footer {
  position: fixed;
  bottom: 0;
  background-color: var(--color-white);
  z-index: z('sticky');
  padding: 1.6rem;
  border-top: 1px solid var(--color-dark-20);
  width: 100%;
  padding-right: 2rem;
  left: 0;
  right: 0;

  @include mq($from: s) {
    display: flex;
    justify-content: flex-end;
  }
}

.mediathek__footer .button + .button {
  margin: 0.8rem 0;
  @include mq($from: s) {
    margin: 0 0.8rem;
  }
}

.mediathek__reset {
  text-decoration: underline;
  margin-left: auto;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}
