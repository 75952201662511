@import '../../styles/fonts/font-mixins';
@import '../../styles/utils/mq';

.form__title {
  @include font-headline(1);
  border: none;
  appearance: none;
  margin-bottom: 2.4rem;
  border-bottom: 1px solid var(--color-dark-20);
  padding-bottom: 0.8rem;
  width: 100%;
  resize: none;

  &:focus {
    border-color: var(--color-primary);
  }

  &::placeholder {
    color: var(--color-dark-20);
  }

  .form--sidebar & {
    margin-top: 3.2rem;
    font-size: 2.4rem;
  }
}

.form__group {
  & + & {
    margin-top: 1.6rem;
  }

  .form__flex & {
    width: 100%;
  }

  .form__channel-image + & {
    margin-left: 1.6rem;
  }

  .page__form-desc + & {
    margin-top: 1.6rem;
  }
}

.form__block {
  margin-top: 3.2rem;
  padding-top: 2rem;
  border-top: 1px solid var(--color-dark-20);

  &:first-child {
    border: none;
    margin-top: 0;
  }

  .form__title + & {
    margin-top: 0;
  }
}

.form__block--noborder {
  border: none;
  padding-top: 0;
}

.form__footer {
  border-top: 1px solid var(--color-dark-20);
  padding: 3.2rem 0;
  margin-top: 3.2rem;

  @include mq($from: s) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.form__status {
  width: 100%;
  flex-shrink: 0;

  .form--sidebar & {
    text-align: left;
  }
}

.form__controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;

  .button + .button,
  .activate-toggle + .button {
    margin-left: 1.6rem;
  }

  .status + & {
    margin-top: 1.6rem;
  }
}

.form__controls-left,
.form__controls-right {
  display: flex;
  align-items: flex-start;
}

.form__controls-right {
  margin-left: auto;
  flex-wrap: wrap-reverse;

  @include mq($from: s) {
    flex-wrap: nowrap;
  }
}

.form__controls--locale {
  justify-content: flex-end;
  margin-top: 3.2rem;

  @include mq($from: s) {
    margin-top: 0;
  }
}

.form__flex {
  display: flex;
  align-items: flex-end;
}

.form__video-image {
  width: 10rem;
  height: 16rem;
  flex-shrink: 0;
  position: relative;
  margin-right: 1.6rem;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .form__play {
    position: absolute;
    bottom: 0.8rem;
    right: 0.8rem;
  }
}

.form__channel-image {
  width: 5.6rem;
  height: 5.6rem;
  overflow: hidden;
  border-radius: var(--border-radius-default);

  img {
    object-fit: cover;
    height: 100%;
  }
}

.form__description {
  color: var(--color-dark-50);
}

.form__textarea--error {
  border-color: var(--color-warning);
  color: var(--color-warning);

  &::placeholder {
    color: var(--color-warning);
  }
}

.form__controls-info {
  color: var(--color-dark-50);
  font-size: 1.2rem;
  display: flex;
  margin-right: 0.4rem;
  width: 100%;
  max-width: 24rem;
  margin-top: 1.6rem;

  @include mq($from: s) {
    margin-top: 0;
  }
}

.form__controls-info-icon {
  margin-right: 0.8rem;
  margin-top: -0.1rem;
}
