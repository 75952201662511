@import '../../styles/utils/list-reset';

.file-progress-item {
  @include list-reset();

  font-size: 1.2rem;
  background-color: var(--color-white);

  & + & {
    margin-top: 0.8rem;
  }
}

.file-progress-item__title {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  flex-grow: 1;
}

.file-progress-item__progress {
  text-align: right;
}

.file-progress-item__close {
  opacity: 0.1;
  transition-property: opacity;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    opacity: 1;
  }
}
