.price {
  font-size: 1.2rem;
  background-color: var(--color-confirm-50);
  display: inline-block;
  padding: 0.4rem 0.8rem;
  font-weight: var(--font-weight-black);
  line-height: 1;
  border-radius: 0.4rem;
}

.price--discount {
  background-color: var(--color-warning-25);
}

.price--crossed {
  text-decoration: line-through;
  opacity: 0.5;
}
.price__wrapper {
  display: flex;
  align-items: center;
  gap: 0.5em;
  &--warning {
    display: flex;
    color: var(--color-warning-75);
    align-self: center;
    align-content: center;
  }
  border-radius: 50%;
}
