@import '../../styles/utils/mq';
@import '../../styles/utils/z-index';
@import '../../styles/utils/list-reset';

.video-download {
  position: relative;
}

.video-download__flyout .react-tooltip-lite {
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  border-radius: var(--border-radius-default);
  background-color: var(--color-white);
  z-index: z('flyout');
  min-width: 12rem;
  padding: 0.8rem !important;
  border-radius: var(--border-radius-default);
  overflow: hidden;
}

.video-download__menu {
  @include list-reset();

  padding-top: 0.8rem;
  margin: 0 -0.8rem;
}

.video-download__url {
  display: block;
  padding: 0.2rem 0.8rem;

  &:hover,
  &:focus {
    text-decoration: underline;
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.video-download__text {
  color: var(--color-dark-50);
  font-size: 1.2rem;
  padding: 0.8rem 0 0;
  max-width: 25rem;
}

@keyframes menuAnimation {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 50rem;
  }
}

@keyframes menuItemAnimation {
  0% {
    transform: translate(0, -1rem);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
