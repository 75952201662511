@import '../../styles/utils/mq';
@import '../../styles/utils/hide-scrollbar';

.creatable-select {
  @include mq($from: m) {
    .select-interactive {
      max-width: 40rem;
    }

    .action-panel & .select-interactive {
      max-width: calc(100% - 2px);
      margin-left: 1px;
    }

    .modal & .select-interactive {
      max-width: 100%;
    }
  }
}

.creatable-select__values {
  font-weight: var(--font-weight-bold);
  font-size: 1.2rem;
  margin-bottom: var(--spacer);

  @include mq($from: m) {
    margin: 0 calc(-1 * $spacer) calc(1 * $spacer);
    font-weight: var(--font-weight-normal);

    .chip {
      margin: $spacer;
    }
  }
}

.creatable-select__max {
  font-weight: var(--font-weight-bold);
}

.creatable-select__error {
  color: var(--color-warning);
}
