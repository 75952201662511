$mq-breakpoints: (
  xs: 480px,
  s: 768px,
  m: 1024px,
  l: 1280px,
  xl: 1441px,
);

$z-index-order: 'modal', 'overlay', 'header', 'sidebar', 'sidebar-overlay',
  'status', 'flyout', 'close', 'sticky', 'pagecollapse', 'panel', 'content';

$colors: (
  'primary' var(--color-primary) var(--color-white),
  'secondary' var(--color-secondary) var(--color-dark),
  'confirm' var(--color-confirm) var(--color-dark),
  'warning' var(--color-warning) var(--color-white),
  'dark' var(--color-dark) var(--color-white)
);
$colorUse: (
  // PRIMARY - primary,action
  'primary' var(--color-primary) var(--color-white),
  'primary-75' var(--color-primary-75) var(--color-dark),
  'primary-50' var(--color-primary-50) var(--color-dark),
  'primary-25' var(--color-primary-25) var(--color-dark),
  'primary-10' var(--color-primary-10) var(--color-dark),
  'primary-petrol' var(--color-primary-petrol) var(--color-white),
  'primary-rgb' var(--color-primary-rgb) var(--color-white),
  'primary-rgb-75' var(--color-primary-rgb-75) var(--color-dark),
  'primary-rgb-50' var(--color-primary-rgb-50) var(--color-dark),
  'primary-rgb-25' var(--color-primary-rgb-25) var(--color-dark),
  'primary-rgb-petrol' var(--color-primary-rgb-petrol) var(--color-white),
  // SECONDARY - emphasis, highlighting
  'secondary' var(--color-secondary) var(--color-dark),
  'secondary-75' var(--color-secondary-75) var(--color-dark),
  'secondary-50' var(--color-secondary-50) var(--color-dark),
  'secondary-25' var(--color-secondary-25) var(--color-dark),
  'secondary-rgb' var(--color-secondary-rgb) var(--color-dark),
  'secondary-rgb-75' var(--color-secondary-rgb-75) var(--color-dark),
  'secondary-rgb-50' var(--color-secondary-rgb-50) var(--color-dark),
  'secondary-rgb-25' var(--color-secondary-rgb-25) var(--color-dark),
  // CONFIRM - affirmation, positive
  'confirm' var(--color-confirm) var(--color-dark),
  'confirm-75' var(--color-confirm-75) var(--color-dark),
  'confirm-50' var(--color-confirm-50) var(--color-dark),
  'confirm-25' var(--color-confirm-25) var(--color-dark),
  'confirm-10' var(--color-confirm-10) var(--color-dark),
  'confirm-petrol' var(--color-confirm-petrol) var(--color-white),
  'confirm-rgb' var(--color-confirm-rgb) var(--color-dark),
  'confirm-rgb-75' var(--color-confirm-rgb-75) var(--color-dark),
  'confirm-rgb-50' var(--color-confirm-rgb-50) var(--color-dark),
  'confirm-rgb-25' var(--color-confirm-rgb-25) var(--color-dark),
  'confirm-rgb-10' var(--color-confirm-rgb-10) var(--color-dark),
  'confirm-rgb-petrol' var(--color-confirm-rgb-petrol) var(--color-white),
  // WARNING - attention, negative
  'warning' var(--color-warning) var(--color-white),
  'warning-75' var(--color-warning-75) var(--color-dark),
  'warning-50' var(--color-warning-50) var(--color-dark),
  'warning-25' var(--color-warning-25) var(--color-dark),
  'warning-10' var(--color-warning-10) var(--color-dark),
  'warning-petrol' var(--color-warning-petrol) var(--color-white),
  'warning-rgb' var(--color-warning-rgb) var(--color-white),
  'warning-rgb-75' var(--color-warning-rgb-75) var(--color-dark),
  'warning-rgb-50' var(--color-warning-rgb-50) var(--color-dark),
  'warning-rgb-25' var(--color-warning-rgb-25) var(--color-dark),
  'warning-rgb-10' var(--color-warning-rgb-10) var(--color-dark),
  'warning-rgb-petrol' var(--color-warning-rgb-petrol) var(--color-white),
  // PROCESS - processing, waiting
  'process' var(--color-process) var(--color-dark),
  'process-75' var(--color-process-75) var(--color-dark),
  'process-50' var(--color-process-50) var(--color-dark),
  'process-25' var(--color-process-25) var(--color-dark),
  // DARK - Text, Lines
  'dark' var(--color-dark-80) var(--color-white),
  'dark-50' var(--color-dark-50) var(--color-white),
  'dark-20' var(--color-dark-20) var(--color-dark),
  'dark-10' var(--color-dark-10) var(--color-dark),
  'dark-rgb' var(--color-dark-rgb) var(--color-white),
  'dark-rgb-80' var(--color-dark-rgb-80) var(--color-white),
  'dark-rgb-50' var(--color-dark-rgb-50) var(--color-white),
  'dark-rgb-20' var(--color-dark-rgb-20) var(--color-dark),
  'dark-rgb-10' var(--color-dark-rgb-10) var(--color-dark)
);

$colorsByName: (
  'white': var(--color-white),
  'lavender': var(--color-lavender),
  'lavender-75': var(--color-lavender-75),
  'lavender-50': var(--color-lavender-50),
  'lavender-25': var(--color-lavender-25),
  'lavender-10': var(--color-lavender-10),
  'lavender-petrol': var(--color-lavender-petrol),
  'rgba-lavender': var(--color-rgb-lavender),
  'rgba-lavender-75': var(--color-rgb-lavender-75),
  'rgba-lavender-50': var(--color-rgb-lavender-50),
  'rgba-lavender-25': var(--color-rgb-lavender-25),
  'rgba-lavender-10': var(--color-rgb-lavender-10),
  'rgba-lavender-petrol': var(--color-rgb-lavender-petrol),
  'rose': var(--color-rose),
  'rose-75': var(--color-rose-75),
  'rose-50': var(--color-rose-50),
  'rose-25': var(--color-rose-25),
  'rgba-rose': var(--color-rgb-rose),
  'rgba-rose-75': var(--color-rgb-rose-75),
  'rgba-rose-50': var(--color-rgb-rose-50),
  'rgba-rose-25': var(--color-rgb-rose-25),
  'pale-beryl': var(--color-pale-beryl),
  'pale-beryl-75': var(--color-pale-beryl-75),
  'pale-beryl-50': var(--color-pale-beryl-50),
  'pale-beryl-25': var(--color-pale-beryl-25),
  'pale-beryl-petrol': var(--color-pale-beryl-petrol),
  'rgba-pale-beryl': var(--color-rgb-pale-beryl),
  'rgba-pale-beryl-75': var(--color-rgb-pale-beryl-75),
  'rgba-pale-beryl-50': var(--color-rgb-pale-beryl-50),
  'rgba-pale-beryl-25': var(--color-rgb-pale-beryl-25),
  'rgba-pale-beryl-petrol': var(--color-rgb-pale-beryl-petrol),
  'pink': var(--color-pink),
  'pink-75': var(--color-pink-75),
  'pink-50': var(--color-pink-50),
  'pink-25': var(--color-pink-25),
  'pink-10': var(--color-pink-10),
  'pink-petrol': var(--color-pink-petrol),
  'rgba-pink': var(--color-rgb-pink),
  'rgba-pink-75': var(--color-rgb-pink-75),
  'rgba-pink-50': var(--color-rgb-pink-50),
  'rgba-pink-25': var(--color-rgb-pink-25),
  'rgba-pink-petrol': var(--color-rgb-pink-petrol),
  'black-80': var(--color-black-80),
  'black-50': var(--color-black-50),
  'black-20': var(--color-black-20),
  'black-10': var(--color-black-10),
  'rgba-black': var(--color-rgb-black),
  'rgba-black-80': var(--color-rgb-black-80),
  'rgba-black-50': var(--color-rgb-black-50),
  'rgba-black-20': var(--color-rgb-black-20),
  'rgba-black-10': var(--color-rgb-black-10),
  'gold': var(--color-gold),
  'gold-75': var(--color-gold-75),
  'gold-50': var(--color-gold-50),
  'gold-25': var(--color-gold-25),
);

// name - background - color - border-color
$statusColors: (
  'live' var(--color-confirm) var(--color-dark) var(--color-secondary-50),
  'draft' var(--color-warning-50) var(--color-dark) transparent,
  'idle' var(--color-dark-20) var(--color-dark) transparent
);

$spacer: var(--spacer);
$spacerMultiplicators: (
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20
);
$spacerBorderWidths: (1, 2);
