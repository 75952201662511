@import '../../styles/utils/list-reset';

.channel-controller {
  @include list-reset();

  display: flex;
  flex-wrap: wrap;
  margin: -0.4rem;
}

.channel-controller__item {
  margin: 0.4rem;
}

.channel-controller__footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 1.6rem;
}

.channel-controller__revert {
  color: var(--color-primary);
  font-weight: var(--font-weight-black);

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.channel-controller__add-box {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 1.6rem;

  .select {
    flex-grow: 1;
    margin-right: 0.8rem;
  }
}
