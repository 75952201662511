@import '../../styles/utils/svg';
@import '../../styles/utils/mq';

.vendor-select {
  margin-right: 1.6rem;
  width: 20rem;

  .select-interactive__menu {
    max-width: 30rem;
    color: var(--color-white);
  }

  .select-interactive__menu-list {
    max-height: 30.8rem;
  }

  .open-select & {
    width: 100%;
    margin-right: 0;

    .select-interactive__menu {
      max-width: 100%;
    }
  }
}

.vendor-select__name {
  margin-right: 2.4rem;
}

.vendor-select-menu__checkboxes {
  margin-top: 1rem;
  display: flex;
}

.vendor-select-menu__checkbox {
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

.vendor-select-menu__checkbox + .vendor-select-menu__checkbox {
  margin-left: 1em;
}

.vendor-select-menu__checkbox--disabled {
  color: var(--vendor-select-menu__checkbox-disabled);
  cursor: not-allowed;
}

.vendor-select-menu__checkbox input[type='checkbox'] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--color-primary);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

.vendor-select-menu__checkbox input[type='checkbox']::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #fff;
  background-color: var(--color-primary);
}

.vendor-select-menu__checkbox input[type='checkbox']:checked::before {
  transform: scale(1);
}

.vendor-select-menu__checkbox input[type='checkbox']:focus {
  outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em);
}
