@import '../../styles/utils/list-reset';
@import '../../styles/utils/z-index';
@import '../../styles/utils/mq';
@import '../../styles/fonts/font-mixins';

.mediathek-item {
  --width: 100%;
  --margin: 0;
  --image-width: 20.2rem;
  --image-height: 33rem;

  @include list-reset();
  border: 1px solid var(--color-dark-20);
  box-shadow: inset 0 0 0 0.2rem var(--color-white);
  background-color: var(--color-white);
  padding: 0.8rem;
  border-radius: var(--border-radius-default);
  width: calc(var(--width) - var(--margin));
  margin: calc(var(--margin) / 2);

  &:hover,
  &:focus {
    box-shadow: inset 0 0 0 0.2rem rgba(var(--color-secondary-rgb), 0.3);
  }

  .localisation-tabs__title {
    @include font-headline(3);

    text-transform: none;
  }

  .localisation-tabs__content {
    margin-top: 1.2rem;
  }

  @include mq($from: s) {
    --width: 25%;
    --margin: 1.6rem;
    --mediaHeaderHeight: 17.6rem;
  }

  @include mq($from: m) {
    --width: 20%;
  }

  @include mq($from: l) {
    --width: 16.6666%;
    --margin: 2rem;
    max-width: var(--image-width);
  }
}

.mediathek-item--open {
  @include mq($from: s) {
    --width: 75%;
  }

  @include mq($from: m) {
    --width: 60%;
  }

  @include mq($from: m) {
    --width: 33.3333%;
  }

  @include mq($from: l) {
    max-width: 100%;
  }
}

.mediathek-item__inner {
  display: flex;
}

.mediathek-item__main {
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  z-index: z('panel');

  .mediathek-item--open & {
    width: calc(50% - 2.4rem - 1px);
    flex-shrink: 0;
  }

  @include mq($from: l) {
    max-width: var(--image-width);
  }
}

.mediathek-item--selected {
  box-shadow: inset 0 0 0 0.2rem var(--color-primary);

  &:hover,
  &:focus {
    box-shadow: inset 0 0 0 0.2rem var(--color-primary);
  }
}

.mediathek-item--selected.mediathek-item--disabled {
  box-shadow: inset 0 0 0 0.2rem rgba(var(--color-primary-rgb), 0.5);
}

.mediathek-item__controls {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0.8rem;
  right: 0.8rem;
  margin: 0.8rem 0;
  z-index: z('panel');
}

.mediathek-item__controls-right {
  display: flex;

  > div {
    margin-left: 0.4rem;
  }
}

.mediathek-item__poster {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.mediathek-item__image {
  position: relative;
  border-radius: var(--border-radius-default);
  overflow: hidden;
  width: var(--image-width);
  height: var(--image-height);

  .lazyimage {
    height: 100%;
  }
}

.mediathek-item__image--player {
  display: flex;

  video {
    object-fit: cover;
  }
}

.mediathek-item__image-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mediathek-item__image-overlay {
  background-color: rgba(256, 256, 256, 0.5);
  color: var(--color-dark-50);
  font-size: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  .mediathek-item--error & {
    background-color: rgba(var(--color-warning-rgb), 0.3);
  }
}

.mediathek-item__meta {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0.8rem;
  left: 0.8rem;
  right: 0.8rem;
  align-items: flex-end;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  z-index: 2;

  .encoding-badge__wrap + .encoding-badge__wrap {
    margin-top: 0.8rem;
  }
}

.mediathek-item__lang {
  background-color: var(--color-dark-20);
  border: 1px solid var(--color-dark);
  border-radius: var(--border-radius-default);
  color: var(--color-dark);
  text-transform: uppercase;
  width: 2.4rem;
  height: 2.4rem;
  font-size: 1.2rem;
  display: table-cell;
  vertical-align: middle;
  line-height: 2.4rem;
  text-align: center;
  flex-shrink: 0;

  &--error {
    color: var(--color-process);
    background-color: var(--color-white);
    border-color: var(--color-process);
  }

  .encoding-badge__wrap + & {
    margin-top: 0.8rem;
  }
}

.mediathek-item__label {
  margin-top: 0.8rem;
  letter-spacing: 0.04rem;
  flex-grow: 1;
}

.mediathek-item__headline {
  font-size: 1.2rem;
  margin-bottom: 0.4rem;

  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.mediathek-item__subtitle-wrap {
  display: flex;
  justify-content: flex-end;
}

.mediathek-item__subtitle {
  font-size: 1rem;
  color: var(--color-dark-50);
  white-space: pre-line;
  word-break: break-all;
}

.mediathek-item__collapse {
  .mediathek-item--open & {
    padding-left: 2rem;
    padding-top: 0.8rem;
    padding-right: 0.8rem;
    position: relative;
    flex-grow: 1;
    min-width: 0;
  }
}

.mediathek-item__close {
  position: absolute;
  top: 0;
  right: 0.8rem;
}

.mediathek-item__fields {
  .input + .select {
    margin-top: 0.8rem;
  }

  .button {
    margin-top: 2.4rem;
    width: 100%;
    text-align: center;
  }

  .button__text {
    width: 100%;
  }
}

.mediathek-item__refresh {
  margin-top: 0.5rem;
}

.mediathek-item__nobrake {
  display: inline-block;
}
