.breadcrumbs {
  color: var(--color-dark-50);
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  padding: 1.6rem 0 6rem;
  overflow-y: auto;
  white-space: nowrap;
}

.breadcrumbs__link {
  margin-right: 0.8rem;

  &:not(:last-child)::after {
    content: '/';
    margin-left: 0.8rem;
    pointer-events: none;
  }

  svg {
    margin-top: -0.4rem;
    margin-right: 0.4rem;
  }
}

.breadcrumbs__link[href]:hover .breadcrumbs__link-text,
.breadcrumbs__link[href]:focus .breadcrumbs__link-text {
  text-decoration: underline;
}
