.video-player {
  --video-width: 20.2rem;
  --video-height: 36rem;
}

.video-player__image {
  width: var(--video-width);
  height: var(--video-height);
  flex-shrink: 0;
  position: relative;
  border-radius: var(--border-radius-default);
}

.video-player__image-wrap {
  position: relative;
  display: inline-block;
}

.video-player__image-overlay {
  background-color: rgba(256, 256, 256, 0.5);
  color: var(--color-dark-50);
  font-size: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.video-player__video {
  animation: fade 0.8s forwards;
  width: var(--video-width);
  height: var(--video-height);
  border-radius: var(--border-radius-default);
  overflow: hidden;
  display: inline-block;
  opacity: 0;
  max-width: 50rem;
  flex-shrink: 0;

  video {
    object-fit: cover;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.video-player__controls {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 1.6rem;

  & > div + div,
  & > div + .button {
    margin-left: 0.8rem;
  }
}

.video-player__lang {
  text-transform: uppercase;
  margin-right: auto;
}

.video-player__error {
  color: var(--color-warning);
  margin-top: 1.6rem;
}

.video-player__wrap {
  display: flex;
  align-items: flex-end;
}

.video-player__status {
  padding: 0 1.6rem;
}

.video-player__status-text {
  color: var(--color-dark-50);
  font-size: 1.2rem;
  margin-bottom: 1.6rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.video-player__status-item {
  & + & {
    margin-top: 2.4rem;
  }
}
