@import '../../styles/utils/mq';
@import '../../styles/utils/z-index';

.page-collapse {
  background-color: var(--color-white);
  overflow: hidden;
  padding: 0.8rem 0;
  position: relative;
  box-shadow: 0px 4px 8px -1px rgba(184, 184, 184, 0.4);
  z-index: z('pagecollapse');
  margin: 0 calc(-2 * var(--spacer));

  @include mq($from: m) {
    padding: 0.8rem 0 1.6rem 0;
    margin: 0 calc(-6 * var(--spacer));
  }

  @include mq($from: xl) {
    margin: 0 calc(-1 * calc((100vw - 144rem + (2 * 6 * var(--spacer))) / 2));
    padding: 2.4rem 0;
  }
}

.page-collapse__inner {
  padding: 0 0.8rem;

  @include mq($from: m) {
    // max-width: calc(10 / 12 * 100vw);
    // margin: 0 auto;
    max-width: 144rem;
    margin-left: calc(1 / 12 * 100vw);
    margin-right: 2.8rem;
    padding: 0;
  }

  @include mq($from: l) {
    margin-right: 6.4rem;
  }

  @include mq($from: xl) {
    margin: 0 auto;
    // margin: 0 calc((100vw - 144rem) / 2);
  }
}

.page-collapse__footer {
  position: absolute;
  top: 3.2rem;
  right: 0.8rem;

  @include mq($from: m) {
    right: 2.8rem;
  }
}
