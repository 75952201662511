.footer {
  text-align: center;
  padding-bottom: 1.2rem;

  .page__section + & {
    margin-top: 3.2rem;
  }
}

.footer__caption {
  margin-bottom: 1.6rem;
}
.footer__link {
  color: var(--color-dark-50);

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
