.progress {
}

.progress__icon-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.progress__icon {
  --gradientWithWhite: linear-gradient(
    0deg,
    rgba(150, 224, 218, 1) 0%,
    rgba(200, 212, 236, 1) 24%,
    rgba(234, 204, 248, 1) 47%,
    rgba(212, 184, 247, 1) 68%,
    rgba(147, 126, 243, 1) 99%,
    rgba(255, 255, 255, 1) 100%
  );

  background: var(--color-confirm);
  background: var(--gradientWithWhite);
  clip-path: url(#progressClip);
  width: 31rem;
  height: 20rem;
}

.progress__overlay,
.progress__border,
.progress__finished {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 31.1rem;
  height: 20.1rem;
}

.progress__border {
  svg {
    width: 31rem;
    height: 20rem;
  }
}

.progress__overlay {
  animation: opacity alternate 1s infinite;
  background-color: var(--color-secondary);
  clip-path: url(#progressClip);
  opacity: 0.5;
  transform: translate(-50%, -50%) rotateZ(360deg);

  .progress--success & {
    animation: none;
    opacity: 0;
  }

  .progress--exception & {
    animation: none;
    opacity: 1;
    background-color: var(--color-warning);
  }
}

.progress__text {
  color: var(--color-dark-50);
  margin: 5.6rem auto 3.2rem;
  max-width: 60rem;
  text-align: center;
}

.progress__title,
.progress__number {
  font-size: 2rem;
  letter-spacing: 0.15em;
  display: block;
}

.progress__subtitle {
  display: block;
  margin-top: 1.2rem;
}

.progress__finished {
  animation: scaleUp 0.8s forwards;
  animation-timing-function: cubic-bezier(0.89, 0.38, 0.12, 0.43);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 64rem;
  transform: translate(-50%, -50%) scale(0.8);
}

.progress__error {
  color: var(--color-warning);
}

@keyframes opacity {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

@keyframes scaleUp {
  0% {
    transform: translate(-50%, -50%) scale(0.8);
  }

  80% {
    transform: translate(-50%, -50%) scale(1);
  }

  100% {
    transform: translate(-50%, -50%) scale(0.95);
  }
}
