@import '../../styles/utils/z-index';
@import '../../styles/utils/mq';
@import '../../styles/abstracts/variables';

@mixin hover {
  &[href]:hover,
  &[href]:focus,
  &[type='button']:hover,
  &[type='button']:focus {
    @content;
  }
}

.icon-button {
  appearance: none;
  border-radius: var(--border-radius-default);
  color: var(--color-dark);
  width: 2.7rem;
  height: 2.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  transition-property: all;
}

.icon-button__container {
  position: relative;
}

.icon-button--disabled {
  cursor: not-allowed !important;
  opacity: 0.6;
}

.icon-button--big {
  width: 3.5rem;
  height: 3.5rem;
  font-size: 1.6rem;
}

.icon-button--blank {
  height: 1.4rem;
}

.icon-button--big.icon-button--blank {
  height: 1.75rem;
}

.icon-button--ghost {
  background-color: var(--color-white);
}

.icon-button__highlight {
  position: absolute;
  top: -0.4rem;
  left: 0;
  transform: translateX(-35%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  line-height: 1;
  background-color: var(--color-white);
  border-radius: 0.4rem;
  padding: 0.3rem 0.5rem;
  pointer-events: none;
}

@each $name, $bgcolor, $color in $colorUse {
  .icon-button--filled {
    &.icon-button--#{$name} {
      background-color: $bgcolor;
      border: 1px solid $bgcolor;
      color: $color;

      @include hover() {
        background-color: var(--color-white);
        color: var(--color-dark);
      }
    }
  }

  .icon-button__highlight--#{$name} {
    border: 1px solid $bgcolor;
  }

  .icon-button--ghost {
    &.icon-button--#{$name} {
      border: 1px solid $bgcolor;

      @include hover() {
        background-color: $bgcolor;
        color: $color;
      }
    }
  }
  .icon-button__highlight--ghost,
  .icon-button__highlight--blank {
    &.icon-button__highlight--#{$name} {
      background-color: $bgcolor;
      color: $color;
    }
  }

  .icon-button--blank {
    &.icon-button--#{$name} {
      color: $bgcolor;
    }
  }
}
