@import '../../styles/abstracts/variables';

$svgSize: 0.6em;
$svgSizeSquare: 0.9em;

.status-icon {
  display: inline-flex;
  width: 1em;
  height: 1em;
  background-color: var(--color-dark-50);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  flex-shrink: 0;

  .icon {
    line-height: $svgSize;
    font-size: $svgSize;
  }
}

.status-icon--square {
  border-radius: 0.4rem;
  width: 1.4em;
  height: 1.4em;

  .icon {
    line-height: $svgSizeSquare;
    font-size: $svgSizeSquare;
  }
}

@each $name, $background, $color in $colorUse {
  .status-icon--#{$name} {
    background-color: $background;
    color: $color;
  }
}
