@mixin hide-scrollbar() {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-y: scroll !important;
  overflow-x: hidden !important;

  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}

@mixin show-scrollbar() {
  scrollbar-width: auto; /* Firefox */
  -ms-overflow-style: auto; /* IE 10+ */
  overflow-y: auto !important;
  overflow-x: auto !important;

  &::-webkit-scrollbar {
    display: auto; /* Chrome Safari */
  }
}
