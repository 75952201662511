@import '../../styles/utils/mq';
@import '../../styles/fonts/font-mixins';

.collapse {
  @include font-caption(var(--color-primary));

  line-height: 1;
}

.collapse__icon {
  margin-top: -0.2rem;
  margin-left: 0.2rem;
}

.collapse__text {
  text-decoration: underline;

  .collapse:hover &,
  .collapse:focus & {
    text-decoration: none;
  }
}
