@import '../../styles/fonts/font-mixins';

.headline--1 {
  @include font-headline(1);
}

.headline--2 {
  @include font-headline(2);

  text-transform: uppercase;
}

.headline--3 {
  @include font-headline(3);
}

.headline--4 {
  @include font-headline(4);
}
