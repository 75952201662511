@import '../../styles/fonts/font-mixins';
// @import '../../styles//utils/list-reset';

ol,
ul {
  list-style-position: inside;
}
.labeled-info-text__form {
  padding-top: 2rem;
}

.labeled-info-text {
  &__paragraph,
  &__list {
    white-space: pre-line;
    overflow-wrap: break-word;
  }
  &__header {
    font-weight: var(--font-weight-bold);
    margin-bottom: 0.6rem;
    line-height: 1.1;
    font-size: 1.2rem;
  }

  &__block {
    & + & {
      margin-top: 1.6rem;
    }
    &:first-child:last-child {
      margin-top: 0;
    }
  }
}

.labeled-info-text__textarea,
.labeled-info-text__select {
  display: block;
  width: 100%;
  font-size: 1.6rem;
  line-height: var(--line-height-default);
  border: none;
  appearance: none;
  background-color: transparent;
  resize: none;
  padding: 0;
}

.labeled-info-text__controls {
  color: var(--color-primary);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 2.8rem;
  height: 8.1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .labeled-info-text__add & {
    height: 2.3rem;
  }
}

.labeled-info-text__button {
  width: 2.7rem;
  height: 2.7rem;
  flex-shrink: 0;
  transition-property: transform, opacity;

  &:focus svg,
  &:hover svg,
  .labeled-info-text__add:hover & svg,
  .labeled-info-text__add:focus & svg {
    transform: scale(1.2);
  }
}

.labeled-info-text__button--up,
.labeled-info-text__button--down,
.labeled-info-text__label-control {
  opacity: 0;

  .labeled-info-text__block:hover &,
  .labeled-info-text__block:focus & {
    opacity: 1;
  }
}

.labeled-info-text__button--up {
  svg {
    transform: rotate(180deg);
  }

  &:focus svg,
  &:hover svg {
    transform: rotate(180deg) scale(1.2);
  }
}

.labeled-info-text__button--add {
  .labeled-info-text--error & {
    color: var(--color-warning);
  }
}

.labeled-info-text__remove:first-child {
  margin-top: auto;
}

.labeled-info-text__add {
  margin-top: 4rem;
  padding: 0 0.8rem;
  height: 3rem;
}

.labeled-info-text__add-text {
  font-size: 1.2rem;
  color: var(--color-dark-50);

  .labeled-info-text__add:hover &,
  .labeled-info-text__add:focus & {
    color: var(--color-primary);
  }

  .labeled-info-text--error &,
  .labeled-info-text--error .labeled-info-text__add:hover &,
  .labeled-info-text--error .labeled-info-text__add:focus & {
    color: var(--color-warning);
  }
}

.labeled-info-text__label-control {
  @include font-caption(var(--color-primary));
  position: absolute;
  top: -2rem;
  right: 0;
  height: 2rem;
  padding-bottom: 0.4rem;
  transition-property: opacity;
  user-select: none;
  display: flex;
  align-items: center;
}

.labeled-info-text__label-control-text {
  display: inline-block;
  margin-right: 0.8rem;
}

.labeled-info-text__label-control-icon {
  display: inline-block;
  font-size: 1.3rem;
  margin-top: -0.2rem;
}

.labeled-info-text--edit {
  font-size: var(--font-size-default);
  line-height: var(--line-height-default);

  .labeled-info-text__block {
    position: relative;
    padding-left: 5rem;
    min-height: 8.1rem;
  }

  .labeled-info-text__add.labeled-info-text__block {
    min-height: 0;
  }
  .labeled-info-text__select {
    min-width: 128px;
    max-width: 196px;
    padding: 0.4rem 0.8rem;
    border-radius: var(--border-radius-default);
    > option[value='header'] {
      font-weight: var(--font-weight-black);
    }
  }
  .labeled-info-text__inputs {
    display: flex;
    gap: 1.6rem;
    align-items: center;
    border-radius: var(--border-radius-default);
    border: 1px solid transparent;
    transition-property: border;
    padding: 0.4rem 0.8rem;
    & > .input {
      width: 100%;
      height: 100%;
    }
  }
  .labeled-info-text__textarea {
    flex-grow: 1;
  }

  .labeled-info-text__block:hover .labeled-info-text__inputs,
  .labeled-info-text__block:focus .labeled-info-text__inputs {
    border-color: var(--color-primary);
  }

  .labeled-info-text__header {
    font-weight: var(--font-weight-black);
  }
}

.labeled-info-text__error {
  color: var(--color-dark-50);
}

.labeled-info-text__field-error {
  margin-top: 1.6rem;
}

.labeled-info-text__tooltip {
  font-size: 1.2rem !important;
  border-radius: var(--border-radius-default) !important;
  padding: 0.8rem !important;
  min-width: 12rem;
  text-align: center !important;
  opacity: 1 !important;
}
