@import 'styles/utils/mq';
@import 'styles/fonts/font-mixins';

.channel-info__controls {
  margin-bottom: 8rem;
  display: flex;
  justify-content: space-between;

  .sidebar & {
    padding-left: 3.2rem;
  }

  @include mq($from: m) {
    margin-bottom: 14.4rem;

    .sidebar & {
      padding-left: 0;
    }
  }
}

.channel-info__controls-right,
.channel-info__controls-left {
  display: flex;

  > * + * {
    margin-left: 0.8rem;
  }
}

.channel-info__block {
  border-top: 1px solid var(--color-dark-20);
  padding-top: 3.2rem;
  clear: both;

  & + & {
    margin-top: 1.6rem;
  }

  &:first-child {
    border-top: 0;
    padding-top: 0;
  }
}

.channel-info__block--noborder,
.channel-info__block--video + .channel-info__block--av,
.channel-info__block--video + .channel-info__block--legal {
  border: 0;
  padding-top: 0;

  .channel-info__block + & {
    margin-top: 3.2rem;
  }
}

.channel-info__more {
  text-align: right;
  padding-top: 0.8rem;
}

.channel-info__more-link {
  @include font-caption(var(--color-primary));
}

.channel-info__header {
  display: flex;
  align-items: center;
  margin-bottom: 3.2rem;
}

.channel-info__image {
  flex-shrink: 0;
  width: 5.6rem;
  height: 5.6rem;
  overflow: hidden;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 1.6rem;
  position: relative;

  img {
    object-fit: cover;
    height: 100%;
  }

  // fallback image for broken src
  img:before {
    content: ' ';
    display: block;
    position: absolute;
    width: 5.6rem;
    height: 5.6rem;
    background-image: url(/images/placeholder-channel.svg);
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.channel-info__text--error {
  color: var(--color-dark-50);
}

.channel-info__av-text {
  color: var(--color-dark-50);
  padding-top: 0.4rem;
  font-size: 1.2rem;
  position: absolute;
}

.channel-info__avdl {
  margin-top: 1.6rem;
  > div {
    margin-top: 0.4rem;
  }

  dt {
    min-width: 4rem;
  }
}
