@import '../../styles/abstracts/variables';
@import '../../styles/utils/z-index';
@import '../../styles/utils/mq';

.navi__inner {
  background-color: var(--color-white);
  padding: 0 0.8rem;

  .sidebar > .navi > &,
  .video-detail-navigator > .navi > & {
    padding: 0.8rem;
    margin: -0.8rem -0.8rem 0;
  }

  .category & {
    border-radius: var(--border-radius-default);
  }

  .modal__content & {
    padding: 0;
  }

  @include mq($from: m) {
    padding: 0.8rem;
  }
}

.navi__in {
  animation: fadeSlideInFromTop 0.3s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.navi__button {
  padding: 1.2rem;
}
