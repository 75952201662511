@import '../../styles/abstracts/variables';
@import '../../styles/utils/mq';
@import '../../styles/utils/z-index';

.video-type {
  .sidebar & {
    height: 100%;
  }
}

.video-type__button {
  width: 100%;
  height: 4.6rem;
  padding: 0 0.8rem;
  appearance: none;
  text-align: left;

  & + & {
    margin-top: 0.2rem;
  }

  &:hover,
  &:focus {
    .video-type__text {
      text-decoration: underline;
    }
  }

  @include mq($from: m) {
    .video-detail__child & {
      padding: 0 calc(var(--spacer) * 7);
    }
  }
}

@each $name, $color in $colorsByName {
  .video-type__button--#{$name} {
    background-color: $color;
  }
}

.video-type__button-sub {
  color: var(--color-dark-50);
  font-size: 1.2rem;
}

.video-type__list {
  .sidebar & {
    margin: 0 calc(-1 * calc(var(--spacer) * 2));
  }

  @include mq($from: m) {
    .video-detail__child & {
      margin: 0 calc(-1 * calc(var(--spacer) * 7));
    }
  }
}

.video-type__button-arrow--disabled {
  opacity: 0.4;
}

.video-type__desc-block {
  color: var(--color-dark-50);

  & + & {
    margin-top: 1.6rem;
  }
}

.video-type__select-all {
  &.checkbox {
    line-height: 1.2em;
  }

  .checkbox__indicator {
    // border-color: var(--color-dark-50);
  }

  .checkbox__input:checked ~ .checkbox__indicator {
    // border-color: var(--color-primary);
  }
}

.video-type__select-list {
  .sidebar & {
    margin: 0 calc(-1 * calc(var(--spacer) * 2));
  }

  @include mq($from: m) {
    .sidebar & {
      margin: 0 calc(-1 * calc(var(--spacer) * 7));
    }

    .sidebar & .list-item-select__option-wrap {
      padding: 0 calc(var(--spacer) * 6);
    }
  }
}

.video-type__select-header {
  .sidebar & {
    padding: 0 0 0.8rem 0.8rem;
  }

  .checkbox {
    line-height: 1;
  }
  @include mq($from: m) {
    .sidebar & {
      padding: 0 calc(var(--spacer) * 7) 0.8rem;
    }
  }
}

.video-type__footer {
  position: sticky;
  bottom: 0;
  z-index: z('sidebar');
  background-color: var(--color-white);
  margin: calc(-1 * calc(var(--spacer) * 2));
  padding: calc(var(--spacer) * 4) calc(var(--spacer) * 2);

  @include mq($from: m) {
    margin: 0;
    padding: calc(var(--spacer) * 4) 0;
  }
}
