@import '../../styles/abstracts/variables';

.status {
  --status-size: 1.2rem;

  font-size: var(--status-size);
  font-weight: var(--font-weight-bold);
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  user-select: none;
  line-height: 1;
}

.status--big {
  --status-size: 1.3rem;
}

.status__marker {
  width: calc(var(--status-size) * 0.9);
  height: calc(var(--status-size) * 0.9);
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.4rem;
  overflow: hidden;
  position: relative;

  @each $name, $color in $colorUse {
    .status--#{$name} & {
      background-color: $color;
    }
  }
}
