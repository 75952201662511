@import '../../styles/fonts/font-mixins';
@import '../../styles/utils/mq';

.channel-video-info__flex {
  display: flex;
  align-items: flex-end;
}

.channel-video-info__preview {
  margin-bottom: 1.6rem;
}

.channel-video-info__block {
  border-top: 1px solid var(--color-dark-20);
  padding-top: 1.6rem;
  & + & {
    margin-top: 1.6rem;
  }
}

.channel-video-info__block--noborder {
  border-top: none;
  padding-top: 0;
}

.channel-video-info__title {
  margin-bottom: 0.8rem;
  margin-top: 2.8rem;
}

.channel-video-info__subtitle {
  color: var(--color-dark-50);
  font-size: 1rem;
}

.channel-video-info__link {
  @include font-caption(var(--color-primary));

  float: right;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.channel-video-info__deactivated {
  margin-right: 0.8rem;
}

.channel-video-info__pro-edit {
  display: flex;
  justify-content: flex-end;
  margin-bottom: -2rem;
}
