@import '../../styles/utils/list-reset';
@import '../../styles/utils/svg';
@import '../../styles/utils/mq';

.localisation-tabs__title-wrap {
  margin-bottom: 2.4rem;
}

.localisation-tabs__title {
  margin-top: 0.4rem;
  .localisation-tabs--status & {
    margin-top: 0.8rem;
  }
}

.localisation-tabs__subtitle {
  color: var(--color-dark-50);
  font-size: 1.2rem;
}

.localisation-tabs__header {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
}

.localisation-tabs__list {
  @include list-reset();

  display: flex;
  flex-wrap: wrap;
  margin: -0.2rem;
}

.localisation-tabs__list-item {
  margin: 0.2rem;
}

.localisation-tabs__content {
  margin-top: 4rem;

  // TODO change when PRoductDetail uses new layout
  .page--detail & {
    margin-top: 2rem;
  }
}

.localisation-tabs__tab.button {
  padding: 0.8rem;
  height: 3.5rem;
  font-size: 1.4rem;
  text-align: center;

  .button__text {
    margin: 0 auto;
  }
}
