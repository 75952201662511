@import '../../../styles/utils/mq';
@import '../../../styles/utils/z-index';
@import '../../../styles/utils/list-reset';
@import '../../../styles/fonts/font-mixins';

.order-detail {
  --detailHeaderHeight: 11rem;
}

.order-detail__modal {
  .modal__content {
    padding: 0;
  }
}

.order-detail__header {
  position: sticky;
  top: 0;
  background-color: var(--color-white);
  height: var(--detailHeaderHeight);
  z-index: z('sticky');
  padding: 1.2rem 0.4rem 0 1.2rem;
  align-items: flex-start;
  border-bottom: 1px solid var(--color-black-20);

  h1 {
    font-size: 1em;
  }

  @include mq($from: s) {
    --detailHeaderHeight: 11rem;
    height: var(--detailHeaderHeight);
    padding: 1.6rem 0.8rem 0 2.4rem;
  }
}

.order-detail__headline {
  font-size: 2.4rem;
  font-weight: var(--font-weight-bold);

  @include mq($from: m) {
    font-size: 3.2rem;
  }
}

.order-detail__orderno {
  font-size: 2.4rem;
}

.order-detail__date {
  font-size: 1.6rem;
  color: var(--color-black-50);
}

.order-detail__content {
  background-color: var(--color-secondary-25);
  min-height: calc(
    100vh - var(--modal-spacer) - var(--modal-spacer) -
      var(--detailHeaderHeight) - 5px
  );
  display: flex;
  flex-direction: column;

  @include mq($from: m) {
    padding: 1.6rem;
  }
}

.order-detail__content-inner {
  display: block;

  @include mq($from: m) {
    display: grid;
    flex-grow: 1;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 0;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
}

.order-detail__col {
  margin-top: 0.4rem;
  min-width: 0;

  @include mq($from: m) {
    padding: 0.8rem;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.order-detail__box {
  border-radius: var(--border-radius-default);
  overflow: hidden;

  & + & {
    margin-top: 0.4rem;
  }

  @include mq($from: m) {
    & + & {
      margin-top: 1.6rem;
    }
  }
}

.order-detail__box--grow {
  @include mq($from: m) {
    flex-grow: 1;
  }
}

.order-detail__box--action {
  border: 1px solid var(--color-warning-50);
  position: relative;
}

.icon-button__container.order-detail__action-marker {
  position: absolute;
  right: 0;
  top: 0.8rem;
}

.order-detail__paylist {
  border-color: var(--color-secondary-50);
}

.order-detail__paylist-label {
  font-size: 1.2rem;
  line-height: 1em;
  line-height: 1.3em;

  @include mq($from: m) {
    font-size: 1.4rem;
  }
}

.order-detail__paylist-price {
  font-size: 1.2rem;

  @include mq($from: m) {
    font-size: 1.4rem;
  }
}

.order-detail__paylist-price--total {
  font-weight: var(--font-weight-bold);
}

.order-detail__widget {
  height: 100%;
}

.order-detail__history {
  margin: 0;
  padding: 0;
  position: relative;
  height: 100%;

  &::after {
    content: '';
    position: absolute;
    left: 0.4rem;
    top: 1.6rem;
    width: 0.1rem;
    height: calc(100% - var(--spacer) * 2);
    background-color: var(--color-black-20);
    z-index: 1;
  }

  @include mq($from: m) {
    &::after {
      height: calc(100% - var(--spacer) * 4);
    }
  }
}

.order-detail__history-item {
  @include list-reset();

  position: relative;
  padding-bottom: 2.4rem;
  padding-left: 1.6rem;

  .order-detail__widget--collapsed & {
    padding-bottom: 0;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0.8rem;
    width: 1em;
    height: 1em;
    background-repeat: no-repeat;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle r='5' cx='5' cy='5' fill='%23333'/%3E%3C/svg%3E");
    z-index: 3;
  }

  &:not(:last-child)::after,
  .order-detail__widget--collapsed &::after {
    content: '';
    position: absolute;
    left: 0.4rem;
    top: 1.6rem;
    width: 0.1rem;
    height: 100%;
    background-color: var(--color-black-50);
    z-index: 2;
  }

  .order-detail__widget--collapsed &::after {
    height: calc(100% + 0.8rem);
  }
}

.order-detail__history-item__date {
  font-size: 1.6rem;
  letter-spacing: 0.13em;
}

.order-detail__history-time {
  color: var(--color-black-50);
  font-size: 1.2rem;
}

.order-detail__history-action {
  font-size: 1.2rem;
}

.order-detail__box-headline {
  @include mq($until: m) {
    @include font-caption(var(--color-dark));
  }
}
