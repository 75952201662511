@import '../../styles/utils/mq';

.video-uploader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;

  .select__label {
    color: var(--color-dark-50);
  }
}

.video-uploader__form {
  display: flex;
  height: 100%;
}

.video-uploader__header {
  padding-top: 1.6rem;
  margin-bottom: 3.2rem;
}

.video-uploader__subtitle {
  color: var(--color-dark-50);
}

.video-uploader__description {
  font-size: 1rem;
  margin-top: 1.2rem;
}

.video-uploader__area {
  background-color: rgba(var(--color-secondary-rgb), 0.2);
  flex-grow: 1;
  margin: 0 -3.2rem;
  padding: 3.2rem;
  display: flex;
  flex-direction: column;

  .modal--full & {
    margin: 0 -3.2rem -1.6rem;
  }

  @include mq($from: m) {
    margin: 0 -4rem;
  }
}

.video-uploader__fields {
  max-width: 28rem;

  .input + .select {
    margin-top: 1.6rem;
  }

  .select + .button {
    margin-top: 3.2rem;
  }
}

.video-uploader__progress {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .button {
    width: auto;
    align-self: center;
  }
}

.video-uploader__button-group {
  display: flex;
  justify-content: center;
  align-items: center;

  .button + .button {
    margin-left: 1.6rem;
  }
}
.video-uploader__footer {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
