.caption {
  @include font-caption(var(--color-dark-50));
}

.text {
  @include font-body();
}

.small {
  @include font-body-small();
}

@each $name, $color in $colorUse {
  .small--#{$name},
  .text--#{$name}  {
    color: $color;
  }

  .caption--#{$name} {
    @include font-caption($color);
  }
}

@each $name, $color in $colorsByName {
  .small--#{$name},
  .text--#{$name} {
    color: $color;
  }

  .caption--#{$name} {
    @include font-caption($color);
  }
}
