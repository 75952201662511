.icon-link__wrap {
  display: inline-block;
}
.icon-link {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  .icon {
    margin-right: 0.3em;
  }
}

.icon-link__text {
  text-decoration: underline;

  .icon-link:hover &,
  .icon-link:focus & {
    text-decoration: none;
  }
}
