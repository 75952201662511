.activate-toggle {
  position: relative;
  display: inline-block;
}

.activate-toggle__button,
.activate-toggle__inner {
  color: var(--color-dark);
  background-color: var(--color-warning-25);
  border: 1px solid var(--color-warning);
  width: 6.7rem;
  height: 3.5rem;
  border-radius: var(--border-radius-default);
  position: relative;
  transition-property: all;
  user-select: none;

  .activate-toggle--active & {
    border-color: var(--color-confirm);
  }

  .activate-toggle--disabled & {
    opacity: 0.5;
  }
  .activate-toggle--disabled &:hover {
    cursor: not-allowed;
  }

  .activate-toggle--loading & {
    color: var(--color-dark-20);
  }

  .activate-toggle--error & {
    opacity: 0.5;
  }
}

.activate-toggle__button {
  .activate-toggle--toggle & {
    background-color: var(--color-dark-10);
    border-color: var(--color-dark-20);
  }

  .activate-toggle--active & {
    background-color: var(--color-confirm-25);
    border-color: var(--color-confirm);
  }
}

.activate-toggle__inner {
  background-color: var(--color-white);
  width: 3.5rem;
  position: absolute;
  top: -1px;
  right: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-property: all;
  transform: translateX(-3.5rem) translateX(3px);

  .activate-toggle--toggle & {
    border-color: var(--color-dark-20);
  }

  .activate-toggle--active & {
    transform: translateX(0);
    border-color: var(--color-confirm);
  }
}

.activate-toggle__desc {
  padding-top: 0.4rem;
  position: absolute;
  top: 100%;
  left: 0;
  color: var(--color-dark-50);
  font-size: 1.2rem;

  .activate-toggle--top & {
    top: -2rem;
    left: auto;
    right: 0;
    text-align: right;
    padding: 0 0 0.4rem;
  }
}

.activate-toggle--top.input--msg-top {
  .input__error {
    top: -2.2rem;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0) scale(1);
  }

  50% {
    transform: rotate(180deg) scale(1.2);
  }

  100% {
    transform: rotate(360deg) scale(1);
  }
}

@keyframes loadingCheck {
  0% {
    color: var(--color-dark);
    transform: scale(1);
  }

  50% {
    color: var(--color-confirm);
    transform: scale(1.2);
  }

  100% {
    color: var(--color-dark);
    transform: scale(1);
  }
}
