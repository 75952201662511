@import '../../styles/utils/mq';

.tabs__list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.tabs__list-item {
  margin-right: 1rem;
  margin-bottom: 1rem;

  @include mq($from: m) {
    margin-bottom: 0;
  }
}
