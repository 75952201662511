@import '../../styles/fonts/font-mixins';
@import '../../styles/utils/list-reset';
@import '../../styles/utils/mq';

.video-card {
  @include list-reset();

  & + & {
    margin-top: 0.4rem;
  }
}

.video-card__button {
  background-color: var(--color-white);
  padding: 0.8rem;
  position: relative;
  border-bottom: 1px solid var(--color-dark-10);
  appearance: none;
  text-align: left;
  width: 100%;
  display: block;

  @each $status, $color in $statusColors {
    .video-card--#{$status} & {
      border-top: 1px solid $color;
    }
  }
}

.video-card__inner {
  width: 100%;
  height: 100%;
}

.video-card__wrap {
  flex-grow: 1;
}

.video-card__content {
  margin-left: 0.8rem;
  flex-grow: 1;
  max-width: 100%;
  min-width: 0;
  min-height: 100%;

  &.flex-box--flex-direction-column {
    height: auto;
  }

  .status-icon {
    margin-right: 0.4rem;
    font-size: 1.6rem;
  }
}

.video-card__title,
.video-card__title span:not(.chip__hint),
.video-card__sub {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 95%;
  overflow: hidden;
}

.video-card__title {
  letter-spacing: 0.07em;
  font-size: 1.4rem;
  max-width: 100%;
  margin-bottom: 0.2rem;

  .chip {
    width: 100%;
  }
}

.video-card__sub {
  color: var(--color-dark-50);
  width: calc(100% - 2rem);
  display: inline-block;
}

.video-card__meta {
  line-height: 1;
}

.video-card__meta .il-item__language {
  margin-left: auto;
}

.video-card__meta .status {
  width: 6.8rem;
}

.video-card__subtitle {
  font-size: 1.2rem;
  line-height: 1em;
  flex-grow: 1;
  white-space: nowrap;
}

.video-card__arrow {
  color: var(--color-dark-20);
  font-size: 1rem;
  position: absolute;
  right: 0.8rem;
  bottom: 0.8rem;
}

.video-card__encoding {
  position: absolute;
  pointer-events: none;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.video-card__language {
  text-transform: uppercase;
  letter-spacing: 0.07em;
  font-weight: var(--font-weight-bold);
  font-size: 1.2rem;

  &.icon {
    transform: scale(1.6);
    color: var(--color-dark-50);
  }
}

.video-card__image {
  overflow: hidden;
  width: 7rem;
  height: 7rem;
  border-radius: 0.6rem;
  flex-shrink: 0;
  position: relative;
  background-color: var(--color-dark-20);

  img {
    object-fit: cover;
    height: 100%;
  }
}

.video-card--channel .video-card__image {
  background: var(--gradient-light);
  border: 1px solid var(--color-primary-25);

  .lazyimage {
    width: 6.4rem;
    height: 6.4rem;
    margin: auto;
    margin-top: 0.3rem;
    border-radius: 0.4rem;
  }
}

.video-card__channel-list {
  padding: 0;
}

.video-card__channel {
  @include list-reset();
  vertical-align: top;
  max-height: 1.9rem;

  & + & {
    margin-left: 0.8rem;
  }
}
