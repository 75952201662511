.channel-header {
  display: flex;
  align-items: center;
}

.channel-header--edit {
  align-items: flex-end;
  flex-wrap: wrap;

  .input {
    flex-grow: 1;
  }

  .input__error {
    width: 100%;
    padding-left: 7.2rem;
  }

  .channel-header__image--error {
    color: var(--color-warning);
    width: 100%;
    font-size: 1.2rem;
    margin-top: 0.4rem;
  }
}

.channel-header__headline {
  margin: 0 3.2rem 0 1.6rem;
  font-size: 1.6rem;
  overflow: hidden;

  .channel-header--edit & {
    font-size: 3.2rem;
    margin-bottom: 0;
  }
}

.channel-header--edit .channel-header__headline--error {
  border-color: var(--color-warning);
  color: var(--color-warning);

  &::placeholder {
    color: var(--color-warning);
  }
}

.channel-header__image {
  background-color: var(--color-secondary);
  background-image: url(/images/placeholder-channel.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 3.6rem;
  height: 3.6rem;
  overflow: hidden;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  cursor: default;

  img {
    object-fit: cover;
    height: 100%;
    max-height: 3.6rem;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    min-height: 100%;
  }

  .channel-header--edit & {
    width: 5.6rem;
    height: 5.6rem;
    background: var(--color-secondary);
    border: 1px solid var(--color-primary);
  }

  .channel-header--edit &--error {
    border-color: var(--color-warning);
  }

  &[type='button'] {
    cursor: pointer;
  }
}

.channel-header__overlay {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.7);
  color: var(--color-primary);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-property: opacity, font-size;

  .channel-header__image:hover & {
    font-size: 2rem;
    opacity: 0.8;
  }

  .channel-header--edit .channel-header__image--error & {
    color: var(--color-warning);
  }
}

.channel-header__tooltip {
  font-size: 1.2rem !important;
  border-radius: var(--border-radius-default) !important;
  padding: 0.8rem !important;
  min-width: 12rem;
  text-align: center !important;
  opacity: 1 !important;
}

.channel-header__av {
  margin-left: auto;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.channel-header__av-text {
  color: var(--color-dark-50);
  padding-right: 0.8rem;
  font-size: 1.2rem;
}
