.avatar {
  display: inline-block;
}
.avatar__content {
  background-color: var(--color-dark);
  width: 2.1rem;
  height: 2.1rem;
  line-height: 2.1rem;
  border-radius: 0.4rem;
  color: var(--color-white);
  text-align: center;
  font-size: 1.2rem;
  font-weight: var(--font-weight-bold);
  cursor: pointer;
}
