.image-input__label {
  margin-bottom: 1rem;
}

.image-input__label--disabled {
  color: var(--color-dark-20);
}

.image-input__error {
  margin-top: 1rem;
}
