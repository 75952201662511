.not-found {
  text-align: center;
  font-size: 1.6rem;

  p {
    margin-bottom: 1.6rem;
  }
}

.not-found__headline {
  color: var(--color-secondary);
  font-size: 8rem;
  user-select: none;
  margin: 4rem 0;
}
