@import '../../styles/abstracts/variables';
@import '../../styles/utils/z-index';

.react-pdf__Document {
  z-index: z('content');

  ::selection {
    color: var(--color-dark);
  }
}

.react-pdf__Page__canvas {
  max-width: 100%;
}
