@import '../../styles/utils/list-reset';

.product-info__channels {
  @include list-reset();

  display: flex;
  flex-wrap: wrap;
  margin: -0.4rem;
}

.product-info__channel-item {
  margin: 0.4rem;
}

.product-info__text--ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.product-info--small {
  font-size: 1.2rem;

  .localisation-tabs .localisation-tabs__tab.button {
    height: 2.7rem;
    min-width: 2.7rem;
    padding: 0 0.8rem;
    font-size: 1.2rem;
  }

  .localisation-tabs__title {
    font-size: 1.2rem;
  }

  .price,
  .label-text__text,
  .labeled-info-text,
  .label-text__label,
  .labeled-info-text__label {
    font-size: 1rem;
  }

  .localisation-tabs__content {
    margin-top: 2.4rem;
  }

  .label-text__text & {
    padding-top: 2rem;
  }
}
