@import '../../styles/utils/list-reset';
@import '../../styles/utils/mq';
@import '../../styles/fonts/font-mixins';

.link-list-item {
  @include list-reset();

  background-color: var(--body-background);
  max-height: 7rem;
  transition-property: max-height;
  transition-duration: 0.6s;

  & + & {
    margin-top: 0.4rem;
  }

  @include mq($from: m) {
    border-radius: var(--border-radius-default);
  }
}

.link-list-item--delete {
  border: 1px solid var(--color-warning);
  max-height: 20rem;
}

.link-list-item--active {
  border: 1px solid var(--color-primary);
}

.link-list-item--edit {
  max-height: 20rem;
}

.link-list-item__inner {
  padding: 0.4rem 0.8rem;

  .avatar + .link-code__wrap {
    margin-left: 0.8rem;
  }

  @include mq($from: m) {
    padding: 0.8rem;
    flex-wrap: wrap;
  }
}

.link-list-item__title span {
  color: var(--color-dark-50);
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow-x: hidden;
  letter-spacing: 0.1rem;

  @include mq($from: m) {
    @include font-body-small(var(--color-dark-50));
    margin-bottom: 0.8rem;
  }
}

.link-list-item__content {
  flex-grow: 1;
  max-width: 100%;

  .input {
    margin-bottom: 1.2rem;
  }

  @include mq($from: m) {
    padding-right: 3.2rem;
    max-width: 90%;
  }
}

.link-list-item__button {
  & + & {
    margin-left: 0.8rem;
  }
}

.link-list-item__delete-msg {
  color: var(--color-warning);
  margin-top: 0.8rem;
  width: 100%;
  padding: 0.4rem 0.8rem;
  animation: fade-in 0.3s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  opacity: 0;

  @include mq($from: m) {
    padding: 0;
  }
}

.link-list-item__more {
  margin-right: -0.6rem;
  margin-top: -2.2rem;
}

.link-list-item__controls {
  animation: fadeSlideInFromRight 0.3s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  opacity: 0;
  min-width: 8rem;

  @include mq($from: m) {
    min-width: 0;
    animation: none;
    opacity: 1;
  }
}

.link-list-item__link-code {
  width: 100%;
  min-height: 3.5rem;
  transition-property: width;
  .link-list-item--interaction & {
    width: calc(100% - 9.6rem);
  }

  .link-code__wrap {
    display: inline-block;
  }
}

.link-list-item__field {
  margin-bottom: 0.8rem;
}
