input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.form {
  &__controls-left {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    gap: 0.5em;
  }
}
