.select-interactive__control.language-control {
  display: inline-block;
  min-height: 2.8rem;
  width: 3.2rem;
  padding: 0 0.4rem;
  margin: 0;

  .select-interactive__value-container {
    padding-left: 0;
    height: 100%;
  }
}
