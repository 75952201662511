.reset-password__submit-btn {
  text-align: right;
  padding-top: 2rem;
}

.reset-password__success-text {
  svg {
    margin-right: 0.8rem;
    color: var(--color-confirm);
  }
}

.reset-password__failure-text {
  svg {
    margin-right: 0.8rem;
    color: var(--color-warning);
  }
}
