.notification-list-item__date {
  margin-right: 1rem;
  font-size: 1.4rem;
  white-space: nowrap;
  color: var(--color-dark);
}

.notification-list-item__lang {
  margin-right: 1rem;
  font-size: 1.4rem;
  color: var(--color-dark);
  padding: 0 0.8rem;
  border-radius: 4px;
  background-color: var(--color-secondary);
}

.notification-list-item__status {
  padding: 0 0.8rem;
  border-radius: 4px;
  font-size: 1.4rem;
  color: var(--color-dark);
  display: flex;
  align-items: center;
  margin-right: 1rem;

  svg {
    width: 1em;
    height: 1em;
    margin-left: 1rem;
  }

  &--pending {
    background-color: var(--color-secondary);
  }

  &--sent {
    background-color: var(--color-confirm);
  }
}

.notification-list-item__action-button {
  & + & {
    margin-left: 0.4rem;
  }
}

.notification-list-item__headline {
}

.notification-list-item__analytics-label {
  text-align: right;
}

.notification-list-item__platform {
  display: flex;
  align-items: center;
}

.notification-list-item__platform-icon {
  width: 1.5em;
  height: 1.5em;
  fill: var(--color-dark-20);
  margin-right: 0.5rem;

  svg {
    vertical-align: top;
  }
}

.notification-list-item__platform-icon--active {
  fill: var(--color-primary);
}
