@import '../../styles/utils/list-reset';
@import '../../styles/fonts/font-mixins';

.list-item {
  @include list-reset();

  background-color: var(--color-white);
  border-radius: var(--border-radius-default);
  display: flex;
  padding: 0.4rem 1.6rem 0.4rem 0.4rem;

  & + & {
    margin-top: 0.4rem;
  }

  .checkbox__wrap {
    align-self: center;
    line-height: calc(1em - 2px);
  }

  .modal & {
    background-color: var(--color-rose-25);
  }
}

.list-item--action {
  box-shadow: inset 0 0 0 0.2rem var(--color-white);
  cursor: pointer;
  &:hover,
  &:focus {
    box-shadow: inset 0 0 0 0.2rem rgba(var(--color-secondary-rgb), 0.3);
  }
}
.list-item--highlighted {
  box-shadow: inset 0 0 0 0.2rem var(--color-secondary);
}

.list-item--active {
  box-shadow: inset 0 0 0 0.2rem var(--color-primary);
  cursor: auto;

  &:hover,
  &:focus {
    box-shadow: inset 0 0 0 0.2rem var(--color-primary);
  }
}

.list-item--border {
  border-top: 1px solid var(--color-dark-20);
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;

  & + & {
    margin-top: 0;
  }

  &:last-child {
    border-bottom: 1px solid var(--color-dark-20);
  }
}

.list-item--selectable {
  padding-left: 1.2rem;
}

.list-item__detail {
  flex-grow: 1;
  flex-shrink: 1;
  margin-left: 1.6rem;
  padding: 0.6rem 0 0.4rem;
  min-width: 0;
}

.list-item__title {
  @include font-headline(3);
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 95%;
  overflow-x: hidden;

  .list-item--deactivated & {
    color: var(--color-dark-50);
  }
}

.list-item__subtitle {
  @include font-body-small(var(--color-dark-50));
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 95%;
  overflow-x: hidden;
}

.list-item__image {
  overflow: hidden;
  width: 5rem;
  height: 5rem;
  border-radius: 0.6rem;
  flex-shrink: 0;
  position: relative;

  .list-item--deactivated & {
    position: relative;
  }

  .list-item--highlight-image & {
    background: var(--gradient-light);

    .lazyimage {
      width: 4.4rem;
      height: 4.4rem;
      margin: auto;
      margin-top: 0.3rem;
      border-radius: 0.4rem;
    }
  }

  img {
    object-fit: cover;
    height: 100%;
  }
}

.list-item__image-overlay {
  background-color: rgba(256, 256, 256, 0.5);
  color: var(--color-dark-50);
  font-size: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  .list-item--error & {
    background-color: rgba(var(--color-warning-rgb), 0.3);
  }
}

.list-item__price {
  align-self: center;
}

.list-item__arrow {
  align-self: center;
  font-size: 1.2rem;
  margin: 0.4rem;
  .list-item__language + &,
  .list-item__videos + & {
    margin-left: 1.6rem;
  }
}

.list-item__language {
  align-self: center;
  flex-shrink: 0;

  &--error {
    color: var(--color-warning);
  }
}

.list-item__videos {
  color: var(--color-warning);
  display: flex;
  align-self: center;
  align-items: center;
  font-weight: var(--font-weight-black);
  flex-shrink: 0;

  .icon {
    font-size: 1.4rem;
  }
}

.list-item__videos-text {
  padding-left: 0.4rem;
}

.list-item__encoding {
  align-self: center;
  margin-right: 1.6rem;
}
