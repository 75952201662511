.lazyimage {
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
    object-position: center;
  }

  img::after {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    background: var(--gradient);
  }
}
.lazyimage__source {
  opacity: 0;
  transition: opacity 1s;
}

.lazyimage__source--loaded {
  opacity: 1;
}
