.steps {
  margin: 0;
  padding: 0;
}

.steps__wrap,
.steps__child {
  height: 100%;
}

.steps__child {
  position: relative;
}
