@import '../../styles/utils/mq';
@import '../../styles/utils/z-index';
@import '../../styles/fonts/font-mixins';
@import '../../styles/utils/svg';

.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: z('header');
  background-color: var(--color-dark);
  color: var(--color-white);
}

.header__inner {
  height: var(--header-height);
  padding: 0 0 0 1.6rem;
  align-items: center;
  display: flex;
  position: sticky;
  top: 0;
  z-index: z('header');
}

.header__logo {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 0.8rem;

  img {
    max-width: 17.8rem;
    backface-visibility: hidden;

    transform: translateZ(0);
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-transform: translate3d(0, 0, 0);
    transform: unset !important;
  }
}

.header__toggle {
  height: 3.8rem;
  width: 3rem;
  position: relative;
}

.header__toggle-icon {
  &,
  &::before,
  &::after {
    content: '';
    left: 0;
    background-color: currentColor;
    height: 0.4rem;
    position: absolute;
    transition-property: transform, opacity;
    width: 3rem;
  }

  &::before {
    transform: translateY(-0.9rem);
  }

  &::after {
    transform: translateY(0.9rem);
  }
}

.header__toggle[aria-expanded='true'] .header__toggle-icon {
  transform: rotate(45deg);

  &::before {
    opacity: 0;
    transform: translateY(0);
  }

  &::after {
    transform: rotate(-90deg);
  }
}

.header__nav {
  align-items: center;
  display: flex;
}

.header__item {
  display: inline-block;
  font-weight: var(--font-weight-black);
  text-transform: uppercase;
  font-size: 1.6rem;
  position: relative;
  margin: 0 1.4rem;
  transition-property: margin;
}

.header__item--active,
.header__item:hover,
.header__item:focus {
  background: var(--color-confirm);
  background: var(--gradient);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.header__item--active {
  margin-left: 3.2rem;
}

.header__item-icon {
  background: var(--color-confirm);
  background: var(--gradient);
  clip-path: url(#activeClip);
  width: 100%;
  height: 2rem;
  position: absolute;
  left: -2.4rem;
  top: 0.65rem;
  opacity: 0;

  .header__item--active & {
    opacity: 1;
  }
}

.header__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  flex: 1;
}

.header__link {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
  transition-property: color;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-dark-50);
    padding-bottom: 0.8rem;
  }

  &:hover,
  &:focus {
    color: var(--color-confirm);
  }
}

.header__link-icon {
  display: block;
  margin-top: -0.5rem;
  margin-right: 0.8rem;
}

.header__vendor {
  align-self: flex-end;
}

.header__vendor-select {
  width: 7.8rem;
  height: var(--header-height);
  border-left: 1px solid var(--color-dark-50);
}

.header__vendor-flyout {
  background-color: var(--color-dark);
  position: fixed;
  padding: 0.8rem 0.8rem 0.4rem;
  border-left: 1px solid var(--color-dark-20);
  animation-name: menuVendorAnimation;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  z-index: z('flyout');
  right: 0;
  top: var(--header-height);
}

.header__vendor-items {
  animation-name: menuVendorItemAnimation;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  transform: translate(0, -1rem);
}

@include mq($until: m) {
  .header__inner {
    padding: 0 1.6rem;
    justify-content: space-between;
  }
  .header__nav {
    background-color: var(--color-dark);
    position: fixed;
    top: var(--header-height);
    width: 100%;
    right: 0;
    height: calc(100% - var(--header-height));
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.6rem;
    z-index: z('flyout');
    animation-name: menuAnimation;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    opacity: 0;
  }

  .header__items {
    flex-grow: 1;
    padding-top: 6.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation-name: menuItemAnimation;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    transform: translate(0, -3rem);
  }

  .header__item {
    display: inline-block;
    margin-bottom: 3.2rem;
    font-size: 2.4rem;
  }

  .header__item-icon {
    top: 1.2rem;
  }

  .header__link {
    font-size: 1.6rem;
  }
}

@include mq($until: s) {
  .header {
    max-width: 100%;
    overflow-x: hidden;
  }

  .header__inner {
    flex-wrap: wrap;
    padding: 0 0.8rem;
  }

  .vendor-select {
    flex-grow: 1;
    width: 100%;
    order: 3;
    margin-right: 0;
    margin-top: -1rem;
  }

  .vendor-select__name {
    order: 2;
  }

  .header__logo {
    order: 1;
  }

  .header__logo img {
    max-width: 13.8rem;
  }

  .header__mobile {
    order: 2;
  }
}

@keyframes menuAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes menuItemAnimation {
  0% {
    transform: translate(0, -3rem);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes menuVendorAnimation {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 50rem;
  }
}

@keyframes menuVendorItemAnimation {
  0% {
    transform: translate(0, -1rem);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
