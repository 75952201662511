@import '../../styles/utils/mq';

:root {
  --video-player-width: 10rem;
  --video-player-height: calc(var(--video-player-width) * 1.78);

  @include mq($from: s) {
    --video-player-width: 20.2rem;
  }
}

.player {
  display: inline-block;
}

.player__image {
  position: relative;
}

.player__image-img {
  width: var(--video-player-width);
  height: var(--video-player-height);
  flex-shrink: 0;
  position: relative;
  border-radius: var(--border-radius-default);
}

.player__video {
  animation: fade-in 0.8s forwards;
  width: var(--video-player-width);
  height: var(--video-player-height);
  border-radius: var(--border-radius-default);
  overflow: hidden;
  opacity: 0;
  max-width: 50rem;
  flex-shrink: 0;

  video {
    object-fit: cover;
    min-width: var(--video-player-width);
  }
}

.player__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--color-white);
  font-size: 3rem;
  z-index: 1;
}
