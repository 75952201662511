@import '../../../styles/utils/hide-scrollbar';
@import '../../../styles/utils/mq';
@import '../../../styles/utils/z-index';
@import '../../../styles/utils/list-reset';
@import '../../../styles/utils/color';

.categories__empty {
  font-weight: var(--font-weight-bold);
}

.categories__button {
  text-decoration: underline;
  padding: 0.5rem 1rem;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}
.error {
  border: 1px solid var(--color-warning);
  border-radius: var(--border-radius-default);
  &--tooltip > .react-tooltip-lite {
    background-color: var(--color-warning-50);
    font-size: 1.2rem;
    padding: 0 0.4rem;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
  }
}
.modal.categories__modal {
  --modal-footer-height: 6.7rem;
  --categories-modal-header-height: 9.4rem;
  --category-card-width: 30rem;

  overflow: hidden;

  .modal__content {
    padding: calc(var(--spacer) * 2);
    padding-top: 0;
    min-height: calc(
      100% - var(--categories-modal-header-height) - var(--modal-footer-height)
    );

    @include mq($from: m) {
      @include show-scrollbar();

      padding: 0;
      background-color: var(--color-secondary-25);
    }
  }

  .modal__fixed-footer {
    height: var(--modal-footer-height);
    overflow: hidden;
    display: block;
  }

  .infinite-scroll-component {
    @include hide-scrollbar();
    overflow: hidden !important;
  }

  @include mq($from: m) {
    --categories-modal-header-height: 14.2rem;

    .list-item-select + .list-item-select {
      margin-top: var(--spacer);
    }

    .list-item-select__option-wrap:hover,
    .list-item-select__option-wrap:focus {
      background-color: var(--color-secondary-50);
    }
  }
}

.categories__modal-header {
  height: var(--categories-modal-header-height);
  position: sticky;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: z('close');

  .modal__header {
    padding: calc(var(--spacer) * 4);
    padding-left: 0;
    flex-direction: row-reverse;

    &::before {
      display: block;
      content: '';
    }

    @include mq($from: m) {
      flex-direction: row;
      padding: 0;
      height: auto;

      .modal--bottom-sheet &,
      .modal--hidden-sidebar & {
        flex-direction: row-reverse;
      }

      &::before {
        content: none;
      }
    }
  }

  @include mq($from: m) {
    border-bottom: 1px solid var(--color-black-20);
    padding: calc(var(--spacer) * 4);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: calc(100vw - var(--modal-spacer) * 2);
  }
}

.categories__search-wrap {
  background-color: var(--color-white);
  position: sticky;
  top: 0;
  z-index: 9999;
  margin: 0 calc(var(--spacer) * 2);

  @include mq($from: m) {
    margin: 0;
    width: var(--category-card-width);
  }
}

.categories__search {
  height: 5rem;
  line-height: 5rem;
  font-size: 1.6rem;

  + .input__clear {
    bottom: 1.4rem;
  }

  @include mq($from: m) {
    height: 3.5rem;
    line-height: 3.5rem;
    font-size: 1.2rem;

    + .input__clear {
      bottom: 0.8rem;
    }
  }
}

.categories__modal-inner {
  margin: 0 calc(-1 * calc(var(--spacer) * 2));
  height: 100%;
  padding-bottom: var(--modal-footer-height);

  .navi {
    padding: 0 calc(var(--spacer) * 2) calc(var(--spacer) * 2);
  }

  @include mq($from: m) {
    margin: 0;
    padding: 0 0 var(--modal-footer-height);
  }
}

.categories__tree {
  @include mq($from: m) {
    margin-top: -2.8rem;

    .caption.tree-select__headline {
      top: var(--categories-modal-header-height);
      background-color: var(--color-secondary-25);
    }
  }
}

.categories__option--parents .checkbox__label {
  padding: var(--spacer) 1.2rem var(--spacer) 0;

  @include mq($from: m) {
    padding-top: var(--spacer) !important;
    padding-bottom: var(--spacer) !important;
  }
}

.categories__list {
  @include list-reset();

  @include mq($from: m) {
    width: var(--category-card-width);
  }
}
