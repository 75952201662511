.help {
  width: 100%;
  max-width: 34rem;
  border-radius: var(--border-radius-default);
  border: 1px solid var(--color-black-20);
}

.help__tooltip {
  .react-tooltip-lite-arrow {
    display: none;
  }
}
