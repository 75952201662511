@import '../../styles/utils/mq';

.grid {
  position: relative;
  // padding: 0 0.8rem;

  @include mq($from: m) {
    margin-top: 3.2rem;
    display: grid;
    grid-template-columns: 1fr minmax(0, 6fr) minmax(0, 5fr);
    grid-template-rows: auto 1fr auto;
    grid-gap: 1.6rem;
    grid-template-areas:
      '. header  sidebar'
      '. main  sidebar'
      '. footer  sidebar';
    padding: 0;
  }

  @include mq($from: xl) {
    display: grid;
    grid-template-columns: 7fr 5fr;
    grid-gap: 1.6rem;
    grid-template-areas:
      'header  sidebar'
      'main  sidebar'
      'footer  sidebar';
  }
}

.grid-item {
  min-width: 0;
}

.grid-item--footer,
.grid-item--header,
.grid-item--main {
  @include mq($from: m) {
    margin-left: -1.6rem;
    align-self: start;
  }
  @include mq($from: xl) {
    margin-left: 0;
  }
}

.grid-item--header {
  @include mq($from: m) {
    grid-area: header;
  }
}
.grid-item--sidebar {
  @include mq($from: m) {
    grid-area: sidebar;
    margin-top: -3.2rem;

    .grid-item__inner {
      margin-right: 2.8rem;
    }
  }

  @include mq($from: xl) {
    margin-right: calc(-1 * calc((100vw - 144rem) / 2));

    .grid-item__inner {
      margin: 0 calc((100vw - 144rem) / 2);
    }
  }
}
.grid-item--main {
  @include mq($from: m) {
    grid-area: main;
  }
}
.grid-item--footer {
  padding-top: 3.2rem;

  @include mq($from: m) {
    grid-area: footer;
  }
}

.grid-item--flex {
  display: flex;
  flex-direction: column;
}

.grid-item--align-start {
  align-items: flex-start;
}

.grid-item--align-end {
  align-items: flex-end;
}

.grid-item--justify-start {
  justify-content: flex-start;
}

.grid-item--justify-end {
  justify-content: flex-end;
}
