.new-password__headline {
  margin-bottom: 0.4rem;
}

.new-password__text {
  margin-bottom: 0.8rem;
}

.new-password__submit-btn {
  text-align: right;
  padding-top: 2rem;
}
