@import '../../styles/abstracts/variables';

.button {
  align-items: center;
  background-color: var(--color-primary);
  border: 1px solid transparent;
  border-radius: var(--border-radius-default);
  display: inline-flex;
  height: 3.5rem;
  min-width: 3.5rem;
  line-height: 1;
  padding: 0 1.6rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.13rem;
  transition-property: all;
  user-select: none;
  justify-content: space-between;

  &[disabled] {
    background-color: var(--color-dark-50);
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:not(div):not(.button--disableFocus):focus {
    outline: 1px solid var(--color-secondary);
    outline-offset: 0;
  }

  &:not(div):not(.button--disableFocus):hover {
    background-color: transparent;
  }

  &.button--disableFocus:focus {
    outline: 0;
  }

  &.button--disableFocus:hover {
    text-decoration: underline;
  }
}

.button.button--small {
  height: 2.8rem;
  min-width: 2.8rem;
  font-size: 1.2rem;
  padding: 0 0.8rem;
  border-radius: 0.4rem;
}

.button--filled {
  &:not(div):not(.button--disableFocus):hover {
    background-color: transparent;
    color: var(--color-dark);
  }

  &.button--primary,
  &.button--warning {
    color: var(--color-white);
  }

  &.button--secondary {
    &:not(div):not(.button--disableFocus):focus {
      outline-color: var(--color-secondary-50);
    }
  }

  &.button--warning {
    &:not(div):not(.button--disableFocus):focus {
      box-shadow: 0px 0px 5px 0px var(--color-warning);
    }
  }
}

.button--ghost {
  background-color: transparent;
  border: 1px solid var(--color-primary);

  &:not(div):not(.button--disableFocus):hover {
    background-color: var(--color-primary);
  }

  &.button--primary {
    &:not(div):not(.button--disableFocus):hover {
      color: var(--color-white);
    }
  }
}

.button__icon {
  width: 1em;
  margin-left: 1.6rem;

  svg {
    display: inline-block;
    font-size: 1em;
    line-height: 0;
    margin-right: 1rem;
    margin-top: -0.2rem;
    height: 1em;
    fill: currentColor;
    user-select: all;
    vertical-align: middle;
    width: 1em;
  }

  .button--small & {
    margin-left: 0.8rem;
  }
}

.button__loader {
  margin-left: 0.8rem;
}

@each $name, $bgcolor, $color in $colorUse {
  .button--filled {
    &.button--#{$name} {
      background-color: $bgcolor;
      border-color: $bgcolor;
      color: $color;
    }
  }

  .button--ghost {
    &.button--#{$name} {
      border: 1px solid $bgcolor;

      &:not(div):not(.button--disableFocus):hover {
        background-color: $bgcolor;
      }
    }
  }
}
