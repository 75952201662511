@import '../../styles//utils//mq';

.search {
  width: 100%;
  position: relative;

  svg {
    position: absolute;
    right: 0.8rem;
    top: 0.9rem;
    color: var(--color-primary);
  }

  .page__controls--list & {
    order: 1;
  }

  @include mq($from: m) {
    width: 26rem;

    .page__controls--list & {
      order: initial;
    }
  }
}
