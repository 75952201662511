$pickerPrimary: rgba(var(--color-secondary-rgb), 0.7);
$pickerSelectedColor: var(--color-secondary);
$pickerKeyboardSelectedColor: $pickerSelectedColor;
$pickerSelectionRangeColor: rgba(var(--color-secondary-rgb), 0.3);
$pickerTextColor: var(--color-dark);
$pickerBorderColor: var(--color-dark-20);
$pickerDisabledColor: var(--color-dark-20);
$pickerBackgroundColor: #f0f0f0;
$pickerHeaderBackgroundColor: #fcf9fe;
$pickerBorderRadius: var(--border-radius-default);
$pickerBorderRadiusSmall: 0.4rem;
$pickerFontBold: var(--font-weight-black);

.date-picker {
  flex-shrink: 0;
  position: static;

  & + & {
    margin-left: 0.8rem;
  }
}

.date-picker__range {
  background-color: $pickerSelectionRangeColor !important;
}
.date-picker__active--start {
  background-color: $pickerSelectionRangeColor !important;
}
.date-picker__active--end {
  background-color: $pickerSelectionRangeColor !important;
}
.date-picker__active {
  background-color: $pickerSelectedColor !important;
  font-weight: var(--font-weight-black);
}

// owerwrites for react-datepicker

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__navigation-icon::before,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-color: $pickerDisabledColor;
  border-width: 0.2rem 0.2rem 0 0;
  height: 0.6rem;
  width: 0.6rem;
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='top']
  .react-datepicker__triangle::before {
  border-bottom-color: $pickerBorderColor;
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::after {
  border-bottom-color: $pickerBorderColor;
}

.react-datepicker-popper[data-placement^='top']
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='top']
  .react-datepicker__triangle::after {
  border-top-color: var(--color-white);
}

.react-datepicker-popper[data-placement^='top']
  .react-datepicker__triangle::before {
  border-top-color: $pickerBorderColor;
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::after {
  border-bottom-color: $pickerHeaderBackgroundColor;
}

.react-datepicker {
  font-family: var(--font-family-default);
  background-color: var(--color-white);
  color: $pickerTextColor;
  border: 1px solid $pickerBorderColor;
  border-radius: $pickerBorderRadius;
  user-select: none;
}

.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: $pickerBorderRadius;
  border-bottom-right-radius: $pickerBorderRadius;
}

.react-datepicker__header {
  background-color: $pickerHeaderBackgroundColor;
  border-bottom: 1px solid $pickerBorderColor;
  border-top-left-radius: $pickerBorderRadius;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: $pickerBorderRadius;
}

.react-datepicker__header--time {
  padding: 0.4rem 0;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: $pickerTextColor;
  font-weight: $pickerFontBold;
}

.react-datepicker__time-container {
  border-left: 1px solid $pickerBorderColor;
}

.react-datepicker__time-container--with-today-button {
  border: 1px solid $pickerBorderColor;
  border-radius: $pickerBorderRadius;
}

.react-datepicker__time-container .react-datepicker__time {
  background: var(--color-white);
  border-bottom-right-radius: $pickerBorderRadius;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  border-bottom-right-radius: $pickerBorderRadius;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background-color: $pickerBackgroundColor;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: $pickerPrimary;
  color: var(--color-dark);
  font-weight: $pickerFontBold;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: $pickerPrimary;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled {
  color: $pickerDisabledColor;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: 2.4rem;
}

.react-datepicker__week-number {
  color: $pickerDisabledColor;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: $pickerBorderRadius;
  background-color: $pickerBackgroundColor;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: $pickerTextColor;
}

.react-datepicker__month--selected,
.react-datepicker__month--in-selecting-range,
.react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border-radius: $pickerBorderRadius;
  background-color: $pickerPrimary;
  color: var(--color-dark);
}

.react-datepicker__month--selected:hover,
.react-datepicker__month--in-selecting-range:hover,
.react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
  background-color: $pickerSelectedColor;
  color: $pickerTextColor;
}

.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: $pickerDisabledColor;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: $pickerBorderRadiusSmall;
  background-color: $pickerBackgroundColor;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-weight: $pickerFontBold;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  border-radius: $pickerBorderRadiusSmall;
  color: var(--color-white);
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: $pickerBorderRadiusSmall;
  background-color: $pickerPrimary;
  color: var(--color-dark);
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: $pickerSelectedColor;
  color: $pickerTextColor;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: $pickerBorderRadiusSmall;
  background-color: $pickerKeyboardSelectedColor;
  color: var(--color-dark);
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: $pickerSelectedColor;
  color: $pickerTextColor;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range {
  background-color: transparent;
  color: $pickerTextColor;
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range,
.react-datepicker__month--selecting-range
  .react-datepicker__month-text--in-range,
.react-datepicker__month--selecting-range
  .react-datepicker__quarter-text--in-range,
.react-datepicker__month--selecting-range
  .react-datepicker__year-text--in-range {
  background-color: $pickerBackgroundColor;
  color: $pickerTextColor;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  color: $pickerDisabledColor;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover,
.react-datepicker__month-text.react-datepicker__month--in-range:hover,
.react-datepicker__month-text.react-datepicker__quarter--selected:hover,
.react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: $pickerPrimary;
}

.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: $pickerBackgroundColor;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border-radius: $pickerBorderRadius;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: $pickerBackgroundColor;
  border-radius: $pickerBorderRadius;
  border: 1px solid $pickerBorderColor;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: $pickerBorderRadiusSmall;
  border-top-right-radius: $pickerBorderRadiusSmall;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  border-bottom-left-radius: $pickerBorderRadiusSmall;
  border-bottom-right-radius: $pickerBorderRadiusSmall;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: $pickerDisabledColor;
}

.react-datepicker__close-icon::after {
  background-color: var(--color-primary);
  color: var(--color-white);
  height: 1.6rem;
  width: 1.6rem;
  padding: 0;
  font-size: 1.6rem;
}

.react-datepicker__today-button {
  background: $pickerBackgroundColor;
  border-top: 1px solid $pickerBorderColor;
  font-weight: $pickerFontBold;
}

.react-datepicker__input-container {
  min-width: 17rem;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  background-color: transparent;
}
