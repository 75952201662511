.landing-page__form-inner {
  display: flex;
  justify-content: space-between;
}

.landing-page__form-column--1 {
  flex-grow: 3;
}

.landing-page__form-column--2 {
  flex-grow: 1;
}

.landing-page__form-image-error {
  margin-top: 1rem;
}

.landing-page__form-image-wrap {
  .image-uploader__preview-img {
    object-fit: contain;
    width: auto;
  }

  .image-uploader__drop-area {
    width: 23rem;
    height: 9rem;
    border-radius: 0;
    overflow: auto;
  }
}
