@import '../../styles/utils/mq';

.icon-tab-bar {
  display: flex;
  align-items: center;
  margin-bottom: 5.6rem;

  .grid & {
    margin-top: 2rem;
  }

  @include mq($from: m) {
    .grid & {
      margin-top: 0rem;
    }
  }
  @include mq($from: l) {
    .grid & {
      margin-top: 2rem;
    }
  }
}

.icon-tab-bar__button {
  color: var(--color-primary);
  opacity: 0.5;
  display: flex;
  align-items: flex-end;
  margin-right: 2.4rem;
  position: relative;
  padding-bottom: 0.5rem;

  & + & {
    margin-left: 2.4rem;
  }

  & + &::before {
    background-color: var(--color-dark-50);
    content: '';
    left: -2.4rem;
    height: 1.2rem;
    width: 1px;
    position: absolute;
  }
}

.icon-tab-bar__button--active {
  opacity: 1;
  border-bottom: 1px solid var(--color-primary);
}

.caption.icon-tab-bar__name {
  color: var(--color-primary);
  margin-left: 0.4rem;
}

.icon-tab-bar__icon {
  font-size: 1.2rem;
}
