@import '../../../styles/utils/z-index';
@import '../../../styles/utils/mq';

.filter-side-bar {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100%;

  .steps__wrap {
    flex-grow: 1;
  }

  > .navi {
    position: sticky;
    top: 0;
    z-index: z('sticky');
  }
}

.filter-side-bar__footer {
  background-color: var(--color-white);
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid var(--color-dark-20);
  z-index: z('sticky');

  .flex-box {
    height: 2.8rem;
  }
}

.filter-side-bar__reset {
  line-height: 1;
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}
