.controls {
  .button + .button,
  .icon-button + .icon-button,
  .icon-button + .button,
  .button + .icon-button {
    margin-left: 0.8rem;
  }
}

.controls__left {
  margin-bottom: 0.8rem;
}
