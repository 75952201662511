.encoding-badge__wrap {
  display: inline-block;
}

.encoding-badge__wrap--overlay {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.encoding-badge {
  border-radius: var(--border-radius-default);

  .encoding-badge__wrap--overlay & {
    height: 100%;
  }
}

.encoding-badge__inner {
  padding: 0.4rem 0.8rem;
  display: inline-flex;
  align-items: center;

  svg {
    margin-right: 0.4rem;
  }

  .loader {
    display: inline-flex;
    margin-right: 0.4rem;
  }

  .encoding-badge__wrap--overlay & {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0.4rem;

    svg {
      margin-right: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .loader {
      margin-right: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.encoding-badge__text {
  .encoding-badge--small & {
    font-size: 1.2rem;
  }

  .encoding-badge__wrap--overlay & {
    font-size: 1rem;
    margin-top: auto;
  }
}

.encoding-badge--error {
  background-color: var(--color-warning-75);

  .encoding-badge__wrap--overlay & {
    background-color: rgba(var(--color-rgba-warning-75), 0.8);
  }
}

.encoding-badge--processing {
  background-color: var(--color-process-25);

  .encoding-badge__wrap--overlay & {
    background-color: rgba(255, 255, 255, 0.8);
  }
}
