@import '../../../styles/utils/mq';
@import '../../../styles/utils/z-index';

.video-detail-navigator {
  > .navi {
    position: sticky;
    top: 0;
    z-index: z('sticky');
  }
}

.video-detail-navigator--secondary {
  background-color: var(--color-secondary-25);

  .sidebar & {
    margin: 0 calc(-1 * calc(var(--spacer) * 2));
    padding: 0 calc(var(--spacer) * 2);
  }
}
.video-detail-navigator {
  @include mq($from: m) {
    > .navi:not(.navi--child) {
      width: 50%;
    }

    > .navi:not(.navi--child) .navi__inner {
      background-color: transparent;
      margin: 0;
      padding: 0;
    }

    > .navi:not(.navi--child) .navi__button {
      position: absolute;
      top: 0;
    }

    > .navi--child .navi__inner {
      margin-right: 0;
    }
  }
}
