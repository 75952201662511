.login {
  position: relative;
}

.login__reset-link {
  color: var(--color-dark-50);
  font-size: 1.4rem;
  display: block;
  text-align: right;

  &:hover {
    text-decoration: underline;
  }
}

.login__submit-btn {
  text-align: right;
  padding-top: 2rem;
}

.login__error {
  position: absolute;
  left: 0;
  bottom: 0;
  color: var(--color-warning);
}
