@import '../../../styles/utils/mq';
@import '../../../styles/utils/z-index';
@import '../../../styles/utils/hide-scrollbar';

$sidebarMaxWidth: 72rem;

.sidebar {
  @include hide-scrollbar();

  background-color: var(--color-white);
  box-shadow: -2px 1px 14px 0 var(--color-dark-20);
  transform: translateX(200%);
  transition-property: transform;
  transition-duration: 0.6s;
  z-index: z('panel');
  width: 100%;
  height: calc(100% - var(--header-height));
  border-radius: 0.8rem 0 0 0;
  position: fixed;
  top: calc(var(--header-height) + 0.8rem);
  left: 0.8rem;
  width: calc(100vw - 0.8rem);
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;

  .body--super-admin & {
    top: calc(var(--header-height) + var(--status-bar-height) + 0.8rem);
  }

  @include mq($from: m) {
    @include show-scrollbar();

    .body--super-admin & {
      top: auto;
    }
  }

  @include mq($from: xl) {
    margin-right: calc(-1 * calc((100vw - 144rem) / 2));
  }
}

.sidebar--open {
  transform: translate(0, 0);
  z-index: z('sidebar');
}

.modal__overlay.sidebar__overlay {
  top: var(--header-height);
  z-index: z('sidebar-overlay');
}

.modal__overlay.sidebar__overlay--removed {
  background-color: transparent;
  pointer-events: none;
}

.sidebar__close {
  animation: fade-in 0.6s;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;
  opacity: 0;
  position: sticky;
  top: 0.4rem;
  left: 0.4rem;
  z-index: z('close');

  .body--super-admin & {
    top: var(--status-bar-height);
  }
}

.sidebar__close-button {
  position: absolute;
  left: 0;
}

.sidebar__content {
  margin-bottom: var(--header-height);
  height: 100%;

  .body--super-admin & {
    top: calc(var(--header-height) + 0.8rem + var(--status-bar-height));
  }

  @include mq($from: xl) {
    max-width: calc(144rem * 5 / 12 - 2 * 3.2rem);
  }
}

.sidebar--grid {
  .sidebar__content {
    padding: calc(var(--spacer) * 2);

    @include mq($from: m) {
      max-width: 60rem;
      overflow-x: hidden;
      position: sticky;
      top: calc(var(--header-height) + 0.8rem);
      height: 100%;
      margin-bottom: 0;
    }
  }

  .sidebar--show-close {
    padding-top: 2.8rem;
  }

  .sidebar__close-button {
    top: -2.8rem;
  }

  @include mq($from: m) {
    transform: translate(0, 0);
    position: relative;
    top: auto;
    bottom: auto;
    right: auto;
    max-width: 100%;
    height: 100%;
    padding: 2.8rem;

    .layout & {
      right: auto;
    }
  }

  @include mq($from: l) {
    padding: 4.2rem 6.4rem;
  }
}

.sidebar--overlay,
.sidebar--narrow {
  .sidebar__close-button {
    left: calc(var(--spacer) * 2);
  }

  &.modal {
    top: 0.8rem;
  }
  .modal__content {
    padding: calc(var(--spacer) * 2);
  }

  @include mq($from: m) {
    top: calc(var(--header-height) + 1.6rem);

    &.modal {
      top: 1.6rem;
      border: 0;
    }

    .modal__content {
      padding: 0;
    }

    .video-detail__child {
      padding: 0 2.8rem;
    }
  }
}

.sidebar--narrow {
  box-shadow: -2px 1px 14px 0 var(--color-dark-50);
  pointer-events: all;

  @include mq($from: m) {
    &.modal {
      max-width: 35rem;
    }
  }
}

.ReactModal__Body--open.modal-body--sidebar-narrow {
  overflow: auto;
}
