@import '../utils/mq';
@import '../utils/z-index';

.page {
  max-width: 144rem;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: var(--header-height);

  .body--super-admin & {
    padding-top: calc(var(--status-bar-height) + var(--header-height));
  }

  &--900 {
    max-width: 90rem;
  }
}

.page--landing {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.page--status {
  padding-top: var(--status-bar-height);
}

.page__subtitle {
  color: var(--color-dark-50);
}

.page__content {
  position: relative;
  padding-bottom: 11rem;
  z-index: z('content');
  padding: 0 0.8rem;

  @include mq($from: m) {
    padding: 0;
  }
}

.page__section {
  & + &:not(.grid) {
    margin-top: 6.4rem;
  }

  &:last-child {
    flex-grow: 1;
  }
}

.page__list {
  margin-top: 3.2rem;

  .page__description + & {
    margin-top: 6.4rem;
  }

  .page__controls + & {
    margin-top: 4rem;
  }
}

.page__landing {
  background-color: var(--color-white);
  padding: 2rem;
  width: 90vw;
  max-width: 40rem;
  border-radius: var(--border-radius-default);
  margin-top: 1.6rem;
  max-height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;

  .input + .input {
    margin-top: 1.6rem;
  }
}

.page__footer {
  text-align: center;
  width: 100%;
  grid-column: 2;
  grid-row: 3;

  @include mq($from: xl) {
    grid-column: 1;
  }
}

.page__controls {
  display: flex;
  flex-wrap: wrap;
  margin: 1.6rem -0.8rem 0;

  & > * {
    margin: 0.8rem;
  }

  & > *:last-child {
    margin-left: auto;
  }

  .action-panel & {
    margin-bottom: 8rem;
    min-height: 5.1rem;
  }

  .page__list + & {
    margin-top: 3.6rem;
  }

  @include mq($from: m) {
    margin-top: 0;

    & > * {
      margin: 0 0.8rem;
    }

    .action-panel & {
      margin-bottom: 14.4rem;
    }

    .page--detail .action-panel & {
      margin-bottom: 1.6rem;
    }

    .page__list + & {
      margin-bottom: -3.6rem;
    }
  }

  &--list {
    align-items: center;
  }
}

.page__controls-left,
.page__controls-right {
  display: flex;

  & > * {
    margin: 0.4rem;
  }
}

.page__controls-list {
  position: relative;

  .page__controls-delete,
  .page__controls-visibility {
    position: absolute;
    left: 4.8rem;
    top: -1.2rem;
  }

  .page__controls-visibility {
    left: 9.1rem;
  }
}

.page--landing .page__footer.footer,
.page--landing .page__logo {
  position: static;
  margin-top: auto;
  color: var(--color-white);
}

.page__landing-headline {
  margin-bottom: 1.4rem;
}

.page__breadcrumb {
  background-color: var(--color-white);

  .breadcrumbs {
    padding: 1.6rem 0 0.8rem;
  }

  @include mq($from: m) {
    .breadcrumbs {
      max-width: 144rem;
      margin: 0 auto;
      padding: 1.6rem 0.8rem 0.8rem;
    }
  }

  @include mq($from: xl) {
    margin: 0 calc(-1 * (100vw - 144rem) / 2);

    .breadcrumbs {
      padding: 1.6rem 0 0.8rem 0;
    }
  }
}

.page__description {
  font-size: 1.2rem;
  padding: 1.6rem 0;

  svg {
    color: var(--color-warning);
    margin-top: -0.2rem;
    margin-right: 0.8rem;
    font-size: 1.6rem;
  }
}

.page__text {
  p + p {
    margin-top: 1.6rem;
  }
}

.page__form-desc {
  max-width: 80rem;
}

.page__help {
  position: fixed;
  right: 1.2rem;
  top: 1.2rem;

  @include mq($from: m) {
    right: 1.2rem;
    bottom: 1.2rem;
    top: auto;
  }
}
