@import '../../styles/utils/list-reset';
@import '../../styles/utils/z-index';
@import '../../styles/utils/svg';
@import '../../styles/utils/mq';
@import '../../styles/utils/hide-scrollbar';

$levelWidth: 22rem;

.tree-select {
  padding: 0;

  .navi {
    position: sticky;
    top: 0;
    margin: 0 calc(-1 * var(--modal-spacer));
    background-color: var(--color-white);
    z-index: z('sticky');
    padding-bottom: calc(var(--spacer) * 2);
  }

  @include mq($from: m) {
    .navi {
      margin: 0;
      border-radius: var(--border-radius-default);
      top: -0.8rem;
    }

    .navi__inner {
      padding: 0;
    }

    .navi__button {
      padding: 0.4rem 0.4rem 0 0.8rem;
    }
  }
}

.tree-select__cards {
  @include mq($from: m) {
    display: flex;
  }
}

.tree-select__card {
  @include mq($from: m) {
    width: 30rem;
    flex-shrink: 0;
    margin-left: calc(var(--spacer) * 4);
  }
}

.tree-select__arrow {
  width: 2rem;
  height: 2rem;
  text-align: center;
  line-height: 3rem;
  border-radius: 50%;

  &:focus {
    background-color: var(--color-primary-50);
  }

  @include mq($from: m) {
    font-size: 1.2rem;
  }
}

.checkbox.tree-select__checkbox {
  font-size: 1.2rem;
  line-height: 1.75;
  width: 100%;
  padding-left: 3.2rem;

  .checkbox__label {
    width: 100%;
  }

  @include mq($from: m) {
    line-height: 3rem;
    padding-left: 3rem;
  }
}

.tree-select__option {
  .checkbox__indicator-icon {
    opacity: 0;
  }
}

.caption.tree-select__headline {
  background-color: var(--color-white);
  text-transform: none;
  font-weight: var(--font-weight-normal);
  position: sticky;
  top: 0;
  padding: 0.4rem;
  z-index: z('sticky');
}
