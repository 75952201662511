@keyframes fadeSlideInFromTop {
  from {
    opacity: 0;
    transform: translateY(-0.8rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeSlideInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-0.8rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeSlideInFromRight {
  from {
    opacity: 0;
    transform: translateX(0.8rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes formSuccess {
  0% {
    border-color: var(--color-confirm);
    outline: 1px solid var(--color-confirm);
  }

  100% {
    border-color: var(--color-dark-20);
    outline-color: transparent;
  }
}
