@import '../../../styles/utils/z-index';
@import '../../../styles/utils/mq';

.video-upload-manager {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0.8rem;
  z-index: z('flyout');

  @include mq($from: m) {
    max-width: 31.2rem;
  }
  @include mq($from: l) {
    max-width: 50rem;
  }
}
