.image-uploader {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image-uploader__drop-area {
  display: block;
  width: 20rem;
  height: 20rem;
  border: 1px dashed var(--color-primary);
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;

  &:hover,
  &:focus {
    border-style: solid;
  }
}

.image-uploader__drop-area--disabled {
  border-color: var(--color-dark-20);
  cursor: default;

  .image-uploader__text {
    color: var(--color-dark-20);
  }

  &:hover,
  &:focus {
    border-style: dashed;
  }
}

.image-uploader__text {
  color: var(--color-dark-50);
  font-size: 1.2rem;
}

.image-uploader__preview-wrap {
  height: 100%;
  width: 100%;
}

.image-uploader__preview-img {
  object-fit: cover;
  min-height: 100%;
  width: 100%;
}

.image-uploader__buttons-wrap {
  margin-top: 1.6rem;
  display: flex;
  justify-content: space-between;

  &--hidden {
    visibility: hidden;
  }
}

.image-uploader__errors {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  margin-right: 0;
  margin-left: 0;
  margin-top: 0.8rem;
}

.image-uploader__error {
  font-size: 1.2rem;
  color: var(--color-warning);
}

.image-uploader__delete-button {
  position: absolute;
  left: 0;
  top: 2px;
}

.image-uploader__desc {
  font-size: 1.2rem;
  margin-top: 1.6rem;
}
