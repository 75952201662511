@import '../../styles/utils/mq';

.product-option {
  padding: 1.2rem;
  background-color: var(--color-white);

  .product-option__wrap--dark & {
    background-color: var(--color-dark);
  }

  .product-option__wrap--create & {
    background-color: var(--color-primary-25);
  }

  .product-option__wrap--create:not(.product-option__wrap--focused) & {
    background-color: var(--color-primary-10);
  }

  .product-select--default-theme & .list-item__title {
    letter-spacing: 0.05rem;
  }

  @include mq($from: m) {
    border-radius: var(--border-radius-default);

    .product-select--default-theme & {
      border-radius: 0;
      padding: 0.8rem 1.2rem;
    }
  }
}

.product-option__inner {
  max-width: 100%;
}

.product-option__wrap {
  & + & {
    margin-top: 0.4rem;
  }
}

.product-option__wrap--create {
  cursor: pointer;
}

.product-option__number {
  font-size: 1.4rem;
  letter-spacing: 0.1em;
  margin-top: var(--spacer);
}

.product-option__content {
  min-width: 0;
}

.product-option__create {
  .list-item__title {
    max-width: 100%;
  }
}
