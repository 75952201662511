@import '../../styles/fonts/font-mixins';

.info-video-list__more {
  text-align: right;
  padding-top: 0.8rem;
}

.info-video-list__more-link {
  @include font-caption(var(--color-primary));
}
