.add-notification-form-wrap {
  position: relative;
}

.add-notification-form-wrap__close-button {
  position: absolute;
  top: -1.5rem;
  right: 0;
}

.add-notification-form__textarea {
  min-height: 10rem;
}

.add-notification-form__action-buttons-container {
  text-align: right;

  > * + * {
    margin-left: 1rem;
  }
}

.add-notification-form__date-info-text {
  margin-top: 1rem;
  font-size: 1.2rem;
  color: --var(--color-dark);
}
