@import '../../styles/utils/mq';

.link-code__wrap {
  display: inline;
  max-width: 100%;
}

.link-code {
  font-family: monospace;
  background-color: var(--color-secondary);
  border: 1px solid var(--color-secondary);
  border-radius: 4px;
  padding: 0 0.8rem;
  line-height: 1.2;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  direction: rtl;
  text-align: right;
  max-width: 100%;

  @include mq($from: m) {
    max-width: none;
  }
}
