@import '../utils/mq';

:root {
  // Colors by Names
  --color-white: #fff;

  --color-lavender: #937ef3;
  --color-lavender-75: #b099f9;
  --color-lavender-50: #c6b6f8;
  --color-lavender-25: #dcd4f5;
  --color-lavender-10: #f4f2fe;
  --color-lavender-petrol: #3b3362;
  --color-rgb-lavender: 147, 126, 243;
  --color-rgb-lavender-75: 176, 153, 249;
  --color-rgb-lavender-50: 198, 182, 248;
  --color-rgb-lavender-25: 220, 212, 245;
  --color-rgb-lavender-10: 244, 242, 254;
  --color-rgb-lavender-petrol: 59, 51, 98;

  --color-rose: #eaccf8;
  --color-rose-75: #f0dff7;
  --color-rose-50: #f1e8f5;
  --color-rose-25: #fcf9fe;
  --color-rgb-rose: 234, 204, 248;
  --color-rgb-rose-75: 240, 223, 247;
  --color-rgb-rose-50: 241, 232, 245;
  --color-rgb-rose-25: 252, 249, 254;

  --color-pale-beryl: #96e0da;
  --color-pale-beryl-75: #9fe7e2;
  --color-pale-beryl-50: #bbeae6;
  --color-pale-beryl-25: #d7eeec;
  --color-pale-beryl-10: #f5fcfc;
  --color-pale-beryl-petrol: #3c5a58;
  --color-rgb-pale-beryl: 150, 224, 218;
  --color-rgb-pale-beryl-75: 159, 231, 226;
  --color-rgb-pale-beryl-50: 187, 234, 230;
  --color-rgb-pale-beryl-25: 215, 238, 236;
  --color-rgb-pale-beryl-10: 245, 252, 252;
  --color-rgb-pale-beryl-petrol: 60, 90, 88;

  --color-pink: #fb59d7;
  --color-pink-75: #fc83e1;
  --color-pink-50: #fabced;
  --color-pink-25: #ffdbf7;
  --color-pink-10: #ffeffb;
  --color-pink-petrol: #5c5263;
  --color-rgb-pink: 251, 89, 215;
  --color-rgb-pink-75: 252, 131, 225;
  --color-rgb-pink-50: 250, 188, 237;
  --color-rgb-pink-25: 255, 219, 247;
  --color-rgb-pink-10: 255, 239, 251;
  --color-rgb-pink-petrol: 92, 82, 99;

  --color-black: #000;
  --color-black-80: #333333;
  --color-black-50: #7f7f7f;
  --color-black-20: #d4d2d6;
  --color-black-10: #f1f1f1;
  --color-rgb-black: 0, 0, 0;
  --color-rgb-black-80: 51, 51, 51;
  --color-rgb-black-50: 127, 127, 127;
  --color-rgb-black-20: 212, 210, 214;
  --color-rgb-black-10: 237, 237, 237;

  --color-gold: #9f8956;
  --color-gold-75: #bfa975;
  --color-gold-50: #dec997;
  --color-gold-25: #ebdab5;

  --gradient: linear-gradient(
    39deg,
    rgba(150, 224, 218, 1) 0%,
    rgba(200, 212, 236, 1) 24%,
    rgba(234, 204, 248, 1) 47%,
    rgba(212, 184, 247, 1) 68%,
    rgba(147, 126, 243, 1) 100%
  );

  --gradient-light: linear-gradient(
    39deg,
    rgba(150, 224, 218, 0.5) 0%,
    rgba(200, 212, 236, 0.5) 24%,
    rgba(234, 204, 248, 0.5) 47%,
    rgba(212, 184, 247, 0.5) 68%,
    rgba(147, 126, 243, 0.5) 100%
  );

  // Colors by use

  // PRIMARY - primary,action
  --color-primary: var(--color-lavender);
  --color-primary-75: var(--color-lavender-75);
  --color-primary-50: var(--color-lavender-50);
  --color-primary-25: var(--color-lavender-25);
  --color-primary-10: var(--color-lavender-10);
  --color-primary-petrol: var(--color-lavender-petrol);
  --color-primary-rgb: var(--color-rgb-lavender);
  --color-primary-rgb-75: var(--color-rgb-lavender-75);
  --color-primary-rgb-50: var(--color-rgb-lavender-50);
  --color-primary-rgb-25: var(--color-rgb-lavender-25);
  --color-primary-rgb-10: var(--color-rgb-lavender-10);
  --color-primary-rgb-petrol: var(--color-rgb-lavender-petrol);

  // SECONDARY - emphasis, highlighting
  --color-secondary: var(--color-rose);
  --color-secondary-75: var(--color-rose-75);
  --color-secondary-50: var(--color-rose-50);
  --color-secondary-25: var(--color-rose-25);
  --color-secondary-rgb: var(--color-rgb-rose);
  --color-secondary-rgb-75: var(--color-rgb-rose-75);
  --color-secondary-rgb-50: var(--color-rgb-rose-50);
  --color-secondary-rgb-25: var(--color-rgb-rose-25);

  // CONFIRM - affirmation, positive
  --color-confirm: var(--color-pale-beryl);
  --color-confirm-75: var(--color-pale-beryl-75);
  --color-confirm-50: var(--color-pale-beryl-50);
  --color-confirm-25: var(--color-pale-beryl-25);
  --color-confirm-10: var(--color-pale-beryl-10);
  --color-confirm-petrol: var(--color-pale-beryl-petrol);
  --color-confirm-rgb: var(--color-rgb-pale-beryl);
  --color-confirm-rgb-75: var(--color-rgb-pale-beryl-75);
  --color-confirm-rgb-50: var(--color-rgb-pale-beryl-50);
  --color-confirm-rgb-25: var(--color-rgb-pale-beryl-25);
  --color-confirm-rgb-10: var(--color-rgb-pale-beryl-10);
  --color-confirm-rgb-petrol: var(--color-rgb-pale-beryl-petrol);

  // WARNING - attention, negative
  --color-warning: var(--color-pink);
  --color-warning-75: var(--color-pink-75);
  --color-warning-50: var(--color-pink-50);
  --color-warning-25: var(--color-pink-25);
  --color-warning-10: var(--color-pink-10);
  --color-warning-petrol: var(--color-pink-petrol);
  --color-warning-rgb: var(--color-rgb-pink);
  --color-warning-rgb-75: var(--color-rgb-pink-75);
  --color-warning-rgb-50: var(--color-rgb-pink-50);
  --color-warning-rgb-25: var(--color-rgb-pink-25);
  --color-warning-rgb-10: var(--color-rgb-pink-10);
  --color-warning-rgb-petrol: var(--color-rgb-pink-petrol);

  // PROCESS - processing, waiting
  --color-process: var(--color-gold);
  --color-process-75: var(--color-gold-75);
  --color-process-50: var(--color-gold-50);
  --color-process-25: var(--color-gold-25);

  // DARK - Text, Lines
  --color-dark: var(--color-black-80);
  --color-dark-80: var(--color-black-80);
  --color-dark-50: var(--color-black-50);
  --color-dark-20: var(--color-black-20);
  --color-dark-10: var(--color-black-10);
  --color-dark-rgb: var(--color-rgb-black-80);
  --color-dark-rgb-50: var(--color-rgb-black-50);
  --color-dark-rgb-20: var(--color-rgb-black-20);
  --color-dark-rgb-10: var(--color-rgb-black-10);

  --font-family-fallback: Arial, sans-serif;
  --font-family-sans: 'Roboto', var(--font-family-fallback);
  --font-size-root: 62.5%;
  --font-size-default: 1.6rem;
  --font-weight-normal: 400;
  --font-weight-bold: 700;
  --font-weight-black: 900;

  --line-height-default: 1.625;

  --body-background: var(--color-secondary-25);

  --default-transition-duration: 0.2s;

  --border-radius-default: 8px;

  --header-height: 7.2rem;

  --status-bar-height: 3.5rem;

  --spacer: 0.3rem;

  --body-margin: 0.8rem;

  --modal-spacer: 0.8rem;
  --modal-header-height: 4.4rem;

  --infiniteScrollHeight: calc(
    100vh - var(--modal-header-height) - var(--modal-spacer)
  );

  @include mq($from: s) {
    --spacer: 0.4rem;
    --body-margin: 1.6rem;
  }
  @include mq($from: m) {
    --body-margin: 0;
    --modal-header-height: 5rem;
    --infiniteScrollHeight: 11rem;
  }

  @include mq($until: s) {
    .body--super {
      --header-height: 10rem;
    }
  }
}
