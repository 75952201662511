.utm__container {
  display: flex;
  justify-content: space-between;
}

.utm__headline {
  margin-bottom: 0.8rem;
}

.utm__icon-button-wrap {
  margin-left: 0.8rem;
}

.utm__add-form {
  display: flex;
  align-items: flex-end;
}

.utm__sources {
  min-width: 30%;
}

.utm__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.utm__list--mediums {
  display: flex;
  flex-wrap: wrap;
}

.utm__list-item--sources {
  margin-bottom: 1.2rem;
  display: flex;
}

.utm__list-item--mediums {
  margin-right: 1.2rem;
  margin-bottom: 1.2rem;
}

.utm__edit-toggle {
  margin-left: 2rem;
}
