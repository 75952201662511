@import '../../styles/utils/mq';
@import '../../styles/utils/svg';

.video-channels__chip {
  max-height: 5.2rem;
  transition-property: max-height;
  transition-duration: 0.6s;

  & + & {
    margin-top: 0.4rem;
  }
}

.video-channels__chip--conflict {
  max-height: 9rem;
}

.video-channels__controls {
  animation: fadeSlideInFromRight 0.3s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  opacity: 0;
  min-width: 8rem;
}

.video-channels__select {
  .select__wrap {
    &::after {
      background-image: svg-url(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" fill="#937ef3"><path d="M99.5 6.6a93 93 0 100 185.9 93 93 0 000-186zM146 108.8h-37.2V146H90.2v-37.2H53V90.2h37.2V53h18.6v37.2H146v18.6z" /></svg>'
      );
      width: 1.3rem;
      height: 1.3rem;
      right: 0.8rem;
    }
  }
  .select-interactive__dropdown-indicator {
    font-size: 1.3rem;
    padding-right: 0.4rem;
  }

  .select-interactive__menu {
    width: 100%;
  }
}
