@import '../../styles/utils/hidden-visually';

.checkbox__wrap {
  position: relative;
}

.checkbox {
  display: inline-block;
  font-size: var(--font-size-default);
  line-height: 1.38em;
  min-height: 2rem;
  padding-left: 3rem;
  position: relative;

  & + & {
    margin-left: 3rem;
  }
}

.checkbox__input {
  @include hidden-visually();

  bottom: 0;
  left: 1rem;
  position: absolute;
}

.checkbox__indicator {
  background-color: var(--color-white);
  border: 2px solid var(--color-primary);
  color: var(--color-white);
  height: 2rem;
  left: 0;
  position: absolute;
  top: 0;
  user-select: none;
  width: 2rem;

  .checkbox__input:checked ~ & {
    background-color: var(--color-primary);
  }

  .checkbox--error & {
    border-color: var(--color-warning);
  }

  .checkbox--error .checkbox__input:checked ~ & {
    background-color: var(--color-warning-50);
    color: var(--color-dark);
  }

  .checkbox--dark & {
    background-color: var(--color-dark);
  }
}

.checkbox__indicator-icon {
  color: #fff;
  font-size: 1.4rem;
  left: 50%;
  line-height: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  .checkbox__input:checked ~ .checkbox__indicator & {
    opacity: 1;
  }

  .checkbox__input:focus ~ .checkbox__indicator & {
    opacity: 0.5;
  }

  .checkbox--error .checkbox__input:checked ~ .checkbox__indicator & {
    color: var(--color-dark);
  }
}

.checkbox__label {
  display: inline-block;
  letter-spacing: 0.3px;

  .checkbox__input[readonly] ~ &,
  .checkbox__input[disabled] ~ & {
    text-decoration: none;
  }

  .checkbox--dark & {
    color: var(--color-white);
  }
}

.checkbox__label-required {
  text-decoration: none;
}

.checkbox__indicator,
.checkbox__label {
  cursor: pointer;

  .checkbox__input[readonly] ~ & {
    cursor: default;
  }

  .checkbox__input[disabled] ~ & {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
