@import '../../styles/utils/mq';

.layout__inner {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: var(--header-height);

  .layout--grid & > * {
    flex-grow: 1;
  }

  .body--super-admin & {
    padding-top: calc(var(--header-height) + var(--status-bar-height));
  }
}
