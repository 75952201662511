@import '../../styles/utils/mq';

.video-delete {
  @include mq($from: m) {
    .label-text__text {
      font-size: 1.6rem;
      line-height: var(--line-height-default);
    }
  }
}
