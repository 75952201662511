@import '../../styles/utils/hide-scrollbar';
@import '../../styles/utils/mq';

.select-interactive {
  user-select: none;
}

.select-interactive__wrapper {
  position: relative;
}

.select-interactive__wrapper--icon {
  .select__select {
    padding-left: 2.8rem;
  }
}

.select-interactive__flipper {
  height: 100%;
}

.select-interactive__drop {
  margin: 0 calc(-1 * calc(var(--spacer) * 2));
  height: 100%;

  @include mq($from: m) {
    margin: 0;
  }
}

.select-interactive__option-wrap {
  & + & {
    margin-top: calc(var(--spacer) * 0.5);
  }
}

.select-interactive__option-wrap--selected {
  position: relative;
  z-index: 2;
}

.select-interactive__option-inner {
  display: flex;
  line-height: 1.38em;
  min-height: 2.2rem;
  padding: calc(var(--spacer) * 4) 1.2rem calc(var(--spacer) * 4) 3.2rem;
  position: relative;
  align-items: center;

  @include mq($from: m) {
    .select-interactive:not(.select-interactive--open-select) & {
      padding: 0.2rem 1.2rem 0.2rem 2.2rem;
    }

    .select-interactive--open-select & {
      padding: calc(var(--spacer) * 2) 1.2rem calc(var(--spacer) * 2) 3.2rem;
    }
  }
}

.select-interactive__check {
  color: var(--color-dark-50);
  left: 0;
  position: absolute;
  top: 0;
  user-select: none;
  height: 5rem;
  width: 3.5rem;

  .select-interactive__option--is-selected & {
    color: var(--color-dark);
  }

  @include mq($from: m) {
    .select-interactive:not(.select-interactive--open-select) & {
      height: 2.6rem;
      width: 2.4rem;
    }

    .select-interactive--open-select & {
      height: 3.8rem;
    }
  }
}

.select-interactive__check-icon {
  color: var(--color-dark);
  font-size: 1.4rem;
  left: 50%;
  line-height: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  .select-interactive__option--is-selected & {
    opacity: 1;
  }

  .select-interactive__option--is-focused:not(.select-interactive__option--is-selected)
    & {
    opacity: 0.3;
  }

  .select-interactive--dark & {
    color: var(--color-white);
  }

  .select-interactive--dark .select-interactive__option--is-selected & {
    color: var(--color-dark);
  }

  @include mq($from: m) {
    .select-interactive:not(.select-interactive--open-select) & {
      font-size: 1rem;
    }
  }
}

.select-interactive__menu-header {
  margin-bottom: 1.6rem;
  padding: 0 1.2rem;
}

.select-interactive__option-icon {
  line-height: 1;
  margin-left: 0;
  margin-right: 0.8rem;
  padding-left: 0;
  margin-bottom: 0.2rem;
  font-size: 1.3rem;
}

.select-interactive__menu-list {
  .infinite-scroll-component {
    @include hide-scrollbar();
  }

  @include mq($from: m) {
    @include hide-scrollbar();
    max-height: 10.8rem;

    .select-interactive--open-select & {
      max-height: 100%;
    }

    .infinite-scroll-component {
      overflow: auto;
    }
  }
}

.select-interactive--dark .select-interactive__indicator {
  color: var(--color-dark-50) !important;
}

.select-interactive__label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  display: block;

  .select-interactive__number + & {
    margin-left: 0.8rem;
  }
}

.select-interactive__number {
  color: var(--color-primary);
  font-size: 1rem;

  .select-interactive__option-number-and-icon & {
    margin-left: auto;
    padding-left: 0.8rem;
    padding-right: 0.4rem;
  }

  .select-interactive--dark & {
    color: var(--color-dark-50);
  }
  .select-interactive__option--is-selected & {
    color: var(--color-dark);
  }

  .select-interactive__value-container--custom & {
    grid-column: 2;
    grid-row: 1;
    margin-left: auto;
    padding-left: 0.4rem;
  }
}

.select-interactive__value-container.select-interactive__value-container--custom {
  line-height: 1;

  .select-interactive__single-value {
    grid-column: 1;
  }
}

.select-interactive__icon {
  position: absolute;
  bottom: 1.7rem;
  left: 0.8rem;
  transform: translateY(50%);
}

.select-interactive__fake-select {
  display: flex;
  align-items: center;
}

.select-interactive__fake-value-icon {
  height: 1.2rem;
  display: inline-flex;
  align-items: center;
  margin-right: var(--spacer);
}

.select-interactive__fake-text {
  flex-grow: 1;
}

.select-interactive__value-container.select-interactive__value-container--icon {
  grid-template-columns: 1.6rem auto;

  .select-interactive__value-icon {
    grid-column: 1;
    grid-row: 1;
  }

  .select-interactive__single-value {
    grid-column: 2;
  }

  .select-interactive__input-container {
    grid-area: 1/2/2/3;
  }
}

.select-interactive__value-container.select-interactive__value-container.select-interactive__value-container--number {
  grid-template-columns: 1fr auto;
}

.select-interactive__value-container.select-interactive__value-container--number.select-interactive__value-container.select-interactive__value-container--icon {
  grid-template-columns: 1.6rem 1fr auto;

  .select-interactive__number {
    grid-column: 3;
  }
}

.select-interactive__add-button {
  color: var(--color-lavender);

  span {
    margin-right: var(--spacer);
  }
}

.select-interactive__new {
  color: var(--color-black-50);
  font-style: italic;
}

.select-interactive__hint {
  background-color: var(--color-dark-10);
  font-size: 1.2rem;

  border-radius: 4px;
  display: inline-block;

  .select-interactive__menu & {
    position: fixed;
    bottom: 0;
    background-color: var(--color-warning-50);
    left: 0;
    right: 0;
    margin: 0 calc(-1 * var(--modal-spacer));
    text-align: center;
    display: block;
    font-size: 1.6rem;
  }

  &.select-interactive__hint--success {
    background-color: var(--color-confirm);
  }

  @include mq($from: m) {
    padding: 0 0.4rem;
  }
}

.select-interactive__clear-indicator {
  color: var(--color-primary);
  padding: 1.2rem;

  @include mq($from: m) {
    padding: 0.8rem;
  }
}

.select-interactive__indicators {
  .loader {
    margin-right: calc(var(--spacer) * 4);

    @include mq($from: m) {
      margin-right: 0;
    }
  }
}

.select-interactive--success .select-interactive__control {
  @include mq($from: m) {
    border-color: var(--color-confirm);
    outline: 1px solid var(--color-confirm);
  }
}

.select-interactive__control-wrap {
  background-color: var(--color-white);
  position: sticky;
  top: 0;
  z-index: 9999;

  .modal__content & {
    margin: 0 calc(-1 * calc(var(--spacer) * 2));
    padding: 1.2rem calc(var(--spacer) * 2) 0;
  }

  .select-interactive--dark & {
    background-color: var(--color-dark);
  }
}

.modal.select-interactive__modal .modal__header {
  padding: calc(var(--spacer) * 4);
  padding-left: 0;
  flex-direction: row-reverse;

  &::before {
    display: block;
    content: '';
  }

  @include mq($from: m) {
    padding: calc(var(--spacer) * 5) calc(var(--spacer) * 5)
      calc(var(--spacer) * 2);
    flex-direction: row;

    .modal--bottom-sheet &,
    .modal--hidden-sidebar & {
      flex-direction: row-reverse;
    }
  }
}

.modal.select-interactive__modal .modal__content {
  padding: calc(var(--spacer) * 2);
  padding-top: 0;
  min-height: calc(100% - var(--modal-header-height));

  @include mq($from: m) {
    min-height: calc(100% - 5rem);
    padding: calc(var(--spacer) * 5) calc(var(--spacer) * 5)
      calc(var(--spacer) * 2);
  }
}
