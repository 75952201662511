.radio {
  font-size: 2rem;
}

.radio__label {
  display: flex;
  align-items: center;
  color: var(--color-primary);
  cursor: pointer;
}

.radio__input {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: transparent;
  /* Not removed via appearance */
  margin: 0;
  margin-right: 0.5rem;

  font: inherit;
  color: currentColor;
  width: 2.15em;
  height: 2.15em;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  display: grid;
  place-content: center;
}

.radio__input::before {
  content: '';
  width: 1.65em;
  height: 1.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 2em 2em var(--color-primary);
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

.radio__input:checked::before {
  transform: scale(1);
}

.radio__input:focus {
  outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em);
}
