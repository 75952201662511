@import '../../styles/utils/list-reset';
@import '../../styles/utils/mq';

.video-upload__wrap {
  margin: 0 calc(-1 * var(--modal-spacer));

  @include mq($from: m) {
    margin: 0 -2rem;
  }
}

.video-upload__drop {
  background-color: var(--color-secondary-50);

  .video-upload__wrap & {
    padding: var(--modal-spacer);
    height: 100%;
  }

  @include mq($from: m) {
    .video-upload__wrap & {
      padding: 1.6rem 2rem;
    }
  }
}

.video-upload__list {
  @include list-reset();
  max-width: 72.6rem;

  .icon-button {
    width: 2rem;
  }

  @include mq($from: m) {
    .icon-button {
      width: 2.7rem;
    }
  }
}

.video-upload__howto {
  height: 100%;
  padding: 2.4rem var(--modal-spacer);
  background-color: transparent;

  @include mq($from: m) {
    padding: 0;

    .howto__inner {
      height: 100%;
    }
  }

  @media (max-height: 660px) {
    padding-bottom: 5.6rem;

    .howto__image img {
      width: 70%;
    }
  }

  @media (max-height: 660px) and (max-width: 48em) {
    padding-bottom: 10rem;
  }
}

.video-upload__drop--active {
  .howto__image {
    animation: zoom 1.6s ease-in-out;
    animation-iteration-count: infinite;
  }

  .howto {
    background-color: transparent;
  }
}

.video-upload__footer {
  .button {
    width: 100%;
  }
  .button__text {
    text-align: center;
    width: 100%;
  }
}

@keyframes zoom {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
