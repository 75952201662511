@import '../../../styles/abstracts/variables';
@import '../../../styles/utils/mq';

$sectionWidth: 100%;
$sectionMediumWidth: 94rem;
$sectionMediumLWidth: 110rem;
$sectionWideWidth: 144rem;

@mixin section-breaker($maxWidth) {
  @media (min-width: $maxWidth) {
    max-width: $maxWidth;

    &.section--break-left .section__inner {
      margin-left: calc(-1 * (100vw - #{$maxWidth}) / 2);
    }

    &.section--break-right .section__inner {
      margin-right: calc(-1 * (100vw - #{$maxWidth}) / 2);
    }
  }
}

.section {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 calc(var(--spacer) * 2);
  max-width: $sectionWidth;

  > * {
    flex-grow: 1;
  }

  @include mq($from: m) {
    padding: 0 calc(var(--spacer) * 6);
  }
}

.section__inner {
  @include mq($from: m) {
    .section.section--break-left .section__inner {
      margin-left: calc(-1 * var(--spacer) * 6);
      padding-left: calc(var(--spacer) * 6);
    }

    .section.section--break-right .section__inner {
      margin-right: calc(-1 * var(--spacer) * 6);
      padding-right: calc(var(--spacer) * 6);
    }
  }
}

.section--breaker .section__inner {
  @include mq($from: m) {
    margin: 0 calc(-1 * var(--spacer) * 6);
    width: auto;
  }
}

.section--wide {
  @include section-breaker($sectionWideWidth);
}

.section--medium {
  @include section-breaker($sectionMediumWidth);
  @include section-breaker($sectionMediumLWidth);
}
