$gridClasses: (
  display: (
    'grid',
    'inline-grid',
  ),
  justify-items: (
    'start',
    'end',
    'center',
    'stretch',
  ),
  align-items: (
    'start',
    'end',
    'center',
    'stretch',
  ),
  justify-content: (
    'start',
    'end',
    'center',
    'stretch',
    'space-around',
    'space-between',
    'space-evenly',
  ),
  align-content: (
    'start',
    'end',
    'center',
    'stretch',
    'space-around',
    'space-between',
    'space-evenly',
  ),
  grid-auto-flow: (
    'row',
    'column',
    'row dense',
    'column dense',
  ),
);

$gridItemClasses: (
  justify-self: (
    'start',
    'end',
    'center',
    'stretch',
  ),
  align-self: (
    'start',
    'end',
    'center',
    'stretch',
  ),
);

@each $property, $propertyList in $gridClasses {
  @each $item in $propertyList {
    .grid-layout--#{$property}-#{$item} {
      #{$property}: #{$item};
    }
  }
}
@each $property, $propertyList in $gridItemClasses {
  @each $item in $propertyList {
    .grid-layout__item--#{$property}-#{$item} {
      #{$property}: #{$item};
    }
  }
}
