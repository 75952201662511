@import '../../styles/fonts/font-mixins';
@import '../../styles/utils/list-reset';
@import '../../styles/utils/mq';

.order-list-item {
  @include list-reset();
  background-color: var(--color-white);
  width: 100%;
  max-width: 100%;

  & + & {
    margin-top: 0.4rem;
  }

  @include mq($from: m) {
    box-shadow: none;
    background-color: transparent;
  }
}

.order-list-item--action {
  box-shadow: inset 0 0 0 0.2rem var(--color-white);
  cursor: pointer;

  &:hover,
  &:focus {
    box-shadow: inset 0 0 0 0.2rem rgba(var(--color-secondary-rgb), 0.3);
  }

  @include mq($from: m) {
    box-shadow: none;

    &:hover,
    &:focus {
      box-shadow: none;
    }
  }
}

.order-list-item__box {
  padding: 0.8rem;

  @include mq($from: m) {
    border-radius: var(--border-radius-default);
    background-color: var(--color-white);

    .order-list-item--action & {
      box-shadow: inset 0 0 0 0.2rem var(--color-white);
      cursor: pointer;
    }

    .order-list-item--action:hover &,
    .order-list-item--action:focus & {
      box-shadow: inset 0 0 0 0.2rem rgba(var(--color-secondary-rgb), 0.3);
    }
  }
}

.order-list-item__number-box {
  border-left: 4px solid var(--color-primary-25);
  margin-right: 0.8rem;
  min-width: 0;

  .order-list-item--error & {
    border-color: var(--color-warning-50);
  }

  .order-list-item--end & {
    border-color: var(--color-confirm);
  }

  @include mq($from: m) {
    padding: 0;
    border: 0;
    border-radius: var(--border-radius-default);
    overflow: hidden;
    background-color: transparent;

    .order-list-item--error &,
    .order-list-item--end & {
      border: 0;
    }

    .order-list-item__box-inner {
      background-color: var(--color-white);
      padding: 0.8rem;
      border-left: 4px solid var(--color-primary-25);
      border-radius: 0 var(--border-radius-default) var(--border-radius-default)
        0;
      min-width: 14rem;

      .order-list-item--error & {
        border-color: var(--color-warning-50);
      }

      .order-list-item--end & {
        border-color: var(--color-confirm);
      }

      .order-list-item--action & {
        box-shadow: inset 0 0 0 0.2rem var(--color-white);
        cursor: pointer;
      }

      .order-list-item--action:hover &,
      .order-list-item--action:focus & {
        box-shadow: inset 0 0 0 0.2rem rgba(var(--color-secondary-rgb), 0.3);
      }
    }
  }
}

.order-list-item__number {
  font-weight: var(--font-weight-bold);
  font-size: 1.6rem;
  line-height: 1.3em;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 95%;
  overflow-x: hidden;
}

.order-list-item__date {
  font-size: 1.2rem;
  color: var(--color-black-50);
}

.order-list-item__title {
  @include font-headline(3);
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 95%;
  overflow-x: hidden;
}

.order-list-item__subtitle {
  @include font-body-small(var(--color-dark-50));
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 95%;
  overflow-x: hidden;
}

.order-list-item__status {
  text-align: right;
  line-height: 1.3;
}

.order-list-item__arrow {
  font-size: 1.2rem;
}
