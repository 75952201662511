@import '../../styles/utils/mq';
@import '../../styles/utils/z-index';

.status-bar {
  background-color: var(--color-confirm);
  text-align: center;
  min-height: var(--status-bar-height);
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0 0.8rem;
  max-width: 100%;
}

.status-bar__text {
  max-width: 100%;
  white-space: nowrap;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: scroll !important;

  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}

.status-bar--warning {
  background-color: var(--color-secondary);
}

.status-bar--error {
  background-color: var(--color-warning-50);
}

.status-bar__wrap {
  position: fixed;
  top: var(--header-height);
  left: 0;
  right: 0;
  z-index: z('status');
  max-width: 100vw;
}

.status-bar__close {
  position: absolute;
  right: 1.6rem;
  top: 0.8rem;
}
